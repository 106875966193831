.ant-collapse {
    .ant-collapse-item {
        .ant-collapse-header {
            padding: 5px 0px 5px 10px !important;
            .anticon {
                left: initial !important;
                right: 16px !important;
            }
        }
    }
}