.marker-cluster-custom {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid  rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-green {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-red {
  background: red;
  border: 5px solid #ededed;
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.bg-black{
  background-color: black;
  color: #ffffff;
}
.bg-red {
  background-color: red;
  color: #ffffff;
}
.bg-green {
  background-color: green;
  color: #ffffff;
}
.bg-yellow {
  background-color: yellow;
  color: #ffffff;
}
.truck-icon {
  height: 0px !important;
}
.truck-icon1 {
  height: 0px !important;
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.boxgooglemap{
	width: 100%;
	height: 700px;
}
.map-label{
  position: absolute;
  margin-left: -7px;
  margin-top: 15px;
  /* transform: rotate(360deg); */
  font-weight: 900;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000;
}
.fg-red {
  color: tomato !important;
}

.fg-dark-red {
  color: red !important;
}

.fg-blue {
  color: dodgerblue !important;
}

.fg-green {
  color: mediumseagreen !important;
}

.fg-orange {
  color: orange !important;
}

.fg-grey {
  color: grey !important;
}

.fg-light-grey {
  color: #d3d3d3 !important;
}

.fg-yellow {
  color: gold !important;
}

.fg-light-green {
  color: #7CFC00 !important;
}

.fg-white {
  color: white !important;
}

.fg-black {
  color: black !important;
}

.fg-pink {
  color: deeppink !important;
}
.bg-red {
  background-color: tomato !important;
}

.bg-dark-red {
  background-color: red !important;
}

.bg-blue {
  background-color: dodgerblue !important;
}

.bg-green {
  background-color: mediumseagreen !important;
}

.bg-orange {
  background-color: orange !important;
}

.bg-grey {
  background-color: grey !important;
}

.bg-black {
  background-color: black !important;
}

.bg-yellow {
  background-color: gold !important;
}

.bg-light-green {
  background-color: #7CFC00 !important;
}

.bg-light-grey {
  background-color: #d3d3d3 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-pink {
  background-color: deeppink !important;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 2px;
  min-width: 30px;
}
.fleet-home-iw label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.markercluster-map { height: 90vh; }
.svg-red svg {
  fill: tomato;
}

.svg-dark-red svg {
  fill: red;
}

.svg-blue svg {
  fill: dodgerblue;
}

.svg-green svg {
  fill: mediumseagreen;
}

.svg-orange svg {
  fill: orange;
}

.svg-grey svg {
  fill: grey;
}

.svg-black svg {
  fill: black;
}

.svg-yellow svg {
  fill: gold;
}

.svg-light-green svg {
  fill: #7CFC00;
}

.svg-white svg {
  fill: white;
}

.svg-pink svg {
  fill: deeppink;
}
.leaflet-marker-icon-wrapper {
  margin-left: -12px;
  margin-top: -11px;
}
.leaflet-cluster-popup {
  max-height: 100px;
  overflow-y: scroll;
}
.map-label-with-text-shadow {
  position: absolute;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000;
}
.partner-marker img {
  width: 32px;
  height: 32px;
}
