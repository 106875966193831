.tables-preview {   
    &-filter {
        float: right;
    }
    &-data {
       clear: both;
    }
}

.report-header > .ant-modal > .ant-modal-content > .ant-modal-header {
    background-color: #6cb382;    
}

.custom_header {
    color: #fff;
}