.lsn-form { 
    &.read-only > div:last-child, &.hide-submit > div:last-child {
        display: none;
    }

    textarea.no-resize{
        resize: none;
    }
    
    input.ant-calendar-picker-input {
        cursor: default !important;
    }

    input:not([type="checkbox"]):read-only, textarea:read-only,
    .ant-select.read-only .ant-select-selection {
        background: #fff;
        resize: none;
        cursor: not-allowed;
        outline: none;
        box-shadow: none;
        & .ant-select-arrow {
            display: none;
        }
        & .ant-select-search {
            display: none;
        }
        & li.ant-select-selection__choice { 
            color: #000;
        }
    }
    input.ant-calendar-range-picker-input:read-only {
        cursor: pointer;
    }

    button[role="switch"].read-only, .ant-select.read-only {
        color: #000;
        cursor: default;
    }
    input:not([type='checkbox']):disabled, textarea:disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        cursor: not-allowed;
        opacity: 1;
    }

    .ant-checkbox-disabled + span {
        color: #000;
        cursor: not-allowed;
        font-size: 14px;
        line-height: 22px;
    }

    .readonly-radio { 
        pointer-events: none;
    }
    
    .hide-spinners input[type="number"]::-webkit-outer-spin-button,
    .hide-spinners input[type="number"]::-webkit-inner-spin-button,
    input[type="number"].hide-spinners::-webkit-outer-spin-button,
    input[type="number"].hide-spinners::-webkit-inner-spin-button {
        display: none;
    }
    .input-wrapper, .ant-radio-group, .ant-select {
        .ant-date-input-readonly {c
            div, input, .ant-calendar-picker-input {
                background: none;
                color: #000;
                input.ant-calendar-range-picker-input:first-of-type {
                    text-align: left;
                }
                input.ant-calendar-range-picker-input:last-of-type {
                    text-align: right;
                }
            }
            i.ant-calendar-picker-icon {
                display: none;
            }
        }
    }
    .input-wrapper.add-option {
        position: relative;
        .option-add-button {
            position: absolute;
            top: 7px;
            right: -25px;
        }
    }
}

.lsn-form.unbordered { 
    &.read-only > div:last-child, &.hide-submit > div:last-child {
        display: none;
    }

    input:read-only, textarea:read-only,
    .ant-select.read-only .ant-select-selection {
        border: none;
    }
    .input-wrapper, .ant-radio-group, .ant-select {
        .ant-date-input-readonly {
            border: none;
            div, input, .ant-calendar-picker-input {
                border: none;
            }
        }
    }
}