
.vehicle-number1 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #3b5a98;
  float: left;
}
.statusRunning1 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color:green;
  padding: 2px 0px;
}

.statusStopped1 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #e6492d;
  padding: 2px 0px;
}
.simIcon{
  width: auto;
  height: 12px;
  margin-left: 10px;
  padding: 0px 5px;
}

