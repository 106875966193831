.icon-set {
  margin-left: 20px;
}

.no-item {
  margin-bottom: 0 !important;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}
