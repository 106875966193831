#IndentForm {
    .input-wrapper {
        flex        : 2;
    }
    & #root_ftlData .form-group.field:nth-child(3) {
        position    : relative;
        left        : -35%;
    }
    #root_ftlData > div.form-group.field.field-string {
        max-width   : 31%;
    }
    label.control-label[for="root_ftlData_lane"] + .input-wrapper.add-option {
        max-width   : calc(67% - 10px);
        margin-left : -20px;
    }
    legend#root_remarksObj__title {
        font-size   : 14px;
    }
}