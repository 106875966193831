.SLAReasons {
  .margin-10 {
    margin-top: 2vw;
  }

  .bold {
    font-weight: 700;
  }

  .sparkline {
    min-width: 100% !important;
  }

  .ant-btn-primary {
    background-color: #2c5de5 !important;
    border-color: #2c5de5 !important;
  }

  .ant-btn-default {
    background-color: #f2f2f5 !important;
    border-color: #f2f2f5
  }

  .status-IN_TRANSIT {
    padding: 5px;
    background-color: #eef3ff;
    color: #2c5de5;
    text-align: center;
  }

  .status-PENDING {
    padding: 5px;
    background-color: #ffe1be;
    color: #e86825;
    text-align: center;
  }
  
  .status-DELIVERED {
    padding: 5px;
    background-color: #dbffdb;
    color: #25e856;
    text-align: center;
  }

  .selected-count {
    color: #1665d8;
  }


  

 
}
.docket-link:hover {
  color: #2c5de5;
}