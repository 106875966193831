.v-docket-list {
  overflow: hidden;

  .atlas-primary-button {
    background-color: #1665d8 !important;
    color: #ffff;
  }

  .atlas-primary-button:disabled {
    background-color: #f2f2f5 !important;
    color: rgba(0, 0, 0, 0.65);
  }

  .docket-sparkline {
    width: 170px;
    height: 75px;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    /* margin: 0 17px 0 0; */
    /* padding: 8px 19px; */
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .docket-card-verified {
      margin: 0 35px !important;
    }
    .title {
      width: 110px;
      height: 18px;
      flex-grow: 0;
      margin: 0 7px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #a0a1a5;
    }
    .highlight {
      width: 120px;
      height: 32px;
      flex-grow: 0;
      margin: 10px 0 0;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
    }
  }

  .tag-input {
    width: 200px;
    margin-right: 5px;
    margin-bottom: 5px;
    float: left;

    input {
      background-color: #f8f8f8;
    }
  }
  .ant-list {
    background-color: #f8f8f8;
    .ant-list-item {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .ant-list-bordered {
    border-color: #f8f8f8;
  }
}

.v-trip-detail {
  .ant-list {
    background-color: #f8f8f8;
    .ant-list-item {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  .ant-list-bordered {
    border-color: #f8f8f8;
  }

  .btn-green {
    background-color: #389e0d !important;
    border-color: #389e0d !important;
  }
  .btn-gray {
    background-color: #bfbfbf !important;
    border-color: #bfbfbf !important;
  }
}

.v-trip-transaction-form .ant-row.ant-form-item {
  margin-bottom: 10px;
}
.verification-status-filters {
  .boxLittle {
    padding: 3px 25px;
    float: left;
    border: 1px solid #999;
    margin: 2px 2px;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;
    font-weight: 400;
    font-size: 14px;
    height: 28px;
  }

  .boxLittle:hover {
    padding: 2px 25px;
    float: left;
    border: 2px solid #999;
    margin: 2px 2px;
    // border-radius: 4px;
    // color: #fff;
    color: #0075f5;
    cursor: pointer;
    background: #eff6fd;
    // border:  0px;
    // background: rgba(59, 90, 152, 0.9);
    border-color: #0075f5;
    // outline-style: solid;
    font-weight: 400;
    font-size: 14px;
    height: 28px;
  }

  .pad10 {
    padding: 3px;
  }

  .boxLittleSelected {
    padding: 2px 25px;
    height: 28px;
    float: left;
    // border:  0px;
    border: 2px solid #999;
    margin: 2px 2px;
    // border-radius: 4px;
    // color: #fff;
    cursor: pointer;
    background: #eff6fd;
    // outline-style: solid;
    // background: rgba(59, 90, 152, 0.9);
    color: #0075f5;
    border-color: #0075f5;
    font-weight: 400;
    font-size: 14px;
  }
  .boxusertitles {
    margin: 7px 10px;
    font-size: 14px;
    font-weight: 500;
  }
}

.v-trip-view-button {
  background: #fbfcfc;
}

.docket-spin > div > .ant-spin .ant-spin-dot {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}

.docket-verify-right {
  &-block {
    // width: 391px;
    width: 100%;
    // height: auto;
    overflow: auto;
    flex-grow: 0;
    // margin: 0px 0 8px 51px; removed for resposive
    padding: 16px 24px 24px 24px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
    background-color: #ffffff;
  }
  &-ctas {
    &-right {
      text-align: right;
    }
    button {
      width: 159px;
    }
    &-success {
      background-image: linear-gradient(to top, #00875a, #00c684) !important;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      border-radius: 4px !important;
      color: #ffffff !important;
    }
    &-success:visited,
    &-success:focus,
    &-success:hover {
      background-image: linear-gradient(to top, #00875a, #00c684) !important;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      border-radius: 4px !important;
      color: #ffffff !important;
    }
    &-primary {
      background-image: linear-gradient(to top, #1665d8, #1f6fe5) !important;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      border-radius: 4px !important;
      color: #ffffff !important;
    }
    &-primary:visited,
    &-primary:focus,
    &-primary:hover {
      background-image: linear-gradient(to top, #1665d8, #1f6fe5) !important;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      border-radius: 4px !important;
      color: #ffffff !important;
    }
    &-default {
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      background-color: #f2f2f5 !important;
      border-radius: 4px !important;
      color: #3e3f42 !important;
    }
    &-default:visited,
    &-default:focus,
    &-default:hover {
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      background-color: #f2f2f5 !important;
      border-radius: 4px !important;
      color: #3e3f42 !important;
    }
  }
  &-shipper-details .ant-list-item {
    padding: 8px !important;
    background-color: #f9f9fa;
  }
  &-shipper .ant-col {
    padding: 0px !important;
  }
  &-shipper-details {
    &-title {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
    }
    &-dataIndex {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
    &-edit {
      cursor: pointer;
      &-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &-table {
      margin-top: 10px;
      &-footer {
        text-align: right;
      }
    }
  }
  &-shipper {
    margin-top: 34px;
  }
}

.docket-verify-left {
  &-block {
    // width: 973px;
    width: 100%;
    height: auto;
    flex-grow: 0;
    margin: 0 24px 21px 0px;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
    background-color: #ffffff;

    &-header {
      &-block1 {
        width: 100%;
        flex-grow: 0;
        margin: 0 16px 12px 0;
        padding: 16px;
        border-radius: 8px;
        border: solid 1px #dfe2e5;
        background-color: #ffffff;
      }
      &-block2 {
        width: 100%;
        flex-grow: 0;
        // margin: 0 0 12px 16px;
        padding: 20px 8px 20px 8px;
        border-radius: 8px;
        border: solid 1px #dfe2e5;
        background-color: #ffffff;

        &-divider {
          width: 1px;
          height: 44px;
          flex-grow: 0;
          transform: rotate(180deg);
          background-color: #dfe2e5;
        }

        &-label {
          margin: 0 4px 0 0;
          font-family: Roboto;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #6b6c6f;
        }
        &-text {
          flex-grow: 0;
          margin: 0 0 0 4px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }     
    }
    &-invoice {
      // width: 973px;
      width: 100%;
      flex-grow: 0;

      color: #424242;
      &-title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      &-columns {
        width: 100%;
        height: 48px;
        flex-grow: 0;
        margin: 12px 0;
        padding: 12px 10px 0px 20px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
        background-color: #ffffff;
        &-data {
          width: 100%;
          height: auto;
          flex-grow: 0;
          margin: 12px 0;
          padding: 20px;
          // padding: 0px 0px 15px 20px;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
          background-color: #ffffff;
        }
      }
      &-header {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        text-align: left;
        // margin: 10px 5px 16px 0;
        &-data {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          word-wrap: break-word;
          margin: 5px 5px 5px 0 !important;
          &-eway-red {
            color: #f44336;
          }
          &-eway-orange {
            color: #ff9800;
          }
          &-eway-green {
            color: #4caf50;
          }
          &-images {
            border-radius: 4px;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.12);
            background-color: #ffffff;
          }
          &-reject {
            color: #e6492d !important;
          }
          &-comment {
            color: #ffffff !important;
          }
          .action-me {
            display: flex;
            justify-content: space-evenly;
          }
          .edit-me {
            align-items: center;
            display: flex;
          }
          .reject-me {
            margin-top: 10px;
          }
          .delete-me {
            align-items: center;
            display: flex;
          }
        }
        .docket-title {
          // position: absolute;
          // margin-left: 12px;     
          width: auto;     
        }

        &-border {
          border-right: 1px solid #dfe2e5;
          // padding: 5px;
          margin-top: 10px;
          max-height: 120px !important;
          min-height: 48px !important;
        }
      }
      &-divider {
        z-index: 10;
        width: 1px;
        height: 50px;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        margin: 0 10px;
        background-color: #dfe2e5;
        /* position: absolute; */
        /* bottom: 12px; */
        /* margin-left: 80px; */
        /* margin-right: 26px; */
        float: right;
        /* text-align: right; */
        position: relative;
        top: 5px;
      }
      &-divider-title {
        z-index: 10;
        width: 1px;
        height: 48px;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        margin: 0 10px;
        background-color: #dfe2e5;

        float: right;

        position: relative;
        bottom: 12px;
      }
      &-data-divider {
        z-index: 10;
        width: 1px;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        margin: 0 130px;
        background-color: #dfe2e5;
        /* position: absolute; */
        /* bottom: 12px; */
        /* margin-left: 80px; */
        /* margin-right: 26px; */
        /* float: right; */
        /* text-align: right; */
        position: absolute;
        bottom: 70px;
        height: 52px;
      }
    }
  }
  &-navigation {
    &-back {
      cursor: pointer;
      float: left;
      &-icon {
        margin: 7px 8px 7px 0;
        font-size: 26px;
        font-weight: 500;
      }
      &-text {
        flex-grow: 0;
        margin: 0 0 0 8px;
        font-family: Roboto;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
    }
    &-pagination {
      // text-align: center;
      display: flex;
      justify-content: space-around;
      &-cta,
      &:hover,
      &:visited,
      &:active,
      &:focus {
        background-color: transparent !important;
        border: 0 !important;
        color: #000000 !important;
      }
      &-center {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        z-index: 10;
      }
      &-left {
        z-index: 10;
      }
      &-right {
        z-index: 10;
      }
    }
  }
}

.image-card {
  width: 100%;
  height: auto;
  flex-grow: 0;
  margin: 12px 0 16px;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  .action-left {
    // float: left;
    // position: relative;
    // top: 205px;
    // left: 10px;
    // height: 22px;
    width: 20px;
    padding-top: 1px;
    padding-left: 3px;
    // padding: 10px 13.3px 10px 14.3px;
    opacity: 0.4;
    border-radius: 4px;
    background-color: #000000;
    margin-left: 8px;
  }
  .action-right {
    width: 20px;
    align-self: flex-end;
    // float: right;
    // position: relative;
    // bottom: 200px;
    // right: 15px;
    height: 22px;
    padding-top: 1px;
    padding-left: 3px;
    opacity: 0.4;
    background-color: #000000;
    border-radius: 4px;
    &-tools {
      width: 40px;
      height: 72px;
      -webkit-flex-grow: 0;
      flex-grow: 0;
      opacity: 0.4;
      border-radius: 8px;
      background-color: #000000;
      transform: rotate(1800deg);
      bottom: 10px;
      position: relative;
      right: 5px;
    }
  }

  &-title {
    flex-grow: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  &-pagination {
    flex-grow: 0;

    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  &-warning {
    float: left;
    width: auto;
    // height: 36px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin: 8px 274px 0 0;
    padding: 5px 8px;
    border-radius: 4px;
    border: solid 0.2px #f44336;
    background-color: rgba(244, 67, 54, 0.8);
    ul {
      width: 138px;
      height: 28px;
      flex-grow: 0;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  &-action {
    float: right;
    margin-top: 10px;
    display: inline-block;
    &-replace,
    &-replace:hover,
    &-replace:visited,
    &-replace:focus {
      width: 120px !important;
      height: 36px !important ;
      // margin: 8px 16px 0 171px;
      // padding: 10px 22.3px 10px 22.3px;
      border-radius: 4px !important;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
      background-color: #01a870 !important;
      color: #ffffff !important;
    }
    &-reject,
    &-reject:hover,
    &-reject:visited,
    &-reject:focus {
      width: 120px !important;
      height: 36px !important;
      // margin: 8px 16px 0 171px;
      // padding: 10px 22.3px 10px 22.3px;
      border-radius: 4px;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
      color: #ffffff !important;
      background-color: #f44336 !important;
    }
  }

  .image-slider {
    width: 100%;
    height: 96px;
    flex-grow: 0;
    margin: 90px 0 0;
    padding: 8px 177px;
    border-radius: 8px;
    border: solid 1px #dfe2e5;
    background-color: #f9f9fa;
    text-align: center;
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      &-item {
        position: relative;
        &-image-delete {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: absolute;
          margin-bottom: 200px;
          width: 20px;
          height: 20px;
          background-color: rgb(255, 255, 255);
          border-radius: 100%;
          border: 1px solid rgb(0, 0, 0);
          color: red;
        }
        &-image {
          // width: 90px;
          height: 48px;
          margin: 5px 10px 5px 8px;
          padding: 12px;
          flex-grow: 0;
          border-radius: 4px;
          border: solid 1px #2568c8;
          object-fit: fill;
          &-upload {
            margin: 16px 24px 16px 8px;
            height: 48px;

            padding: 12px;
            flex-grow: 0;
            border-radius: 4px;
            border: solid 1px #2568c8;
          }
        }
        &-selected {
          width: 95px !important;
          height: 70px !important;
          flex-grow: 0;

          border-radius: 4px;
          // border: solid 1px #4a54e4;
          border: solid 1px #cbcbcb;
          object-fit: fll;
        }
        &-viewer {
          width: 90px;
          height: 66px;
          flex-grow: 0;

          border-radius: 4px;
          border: solid 1px #cbcbcb;
          object-fit: fll;
        }
      }
    }
  }
}
.img-card-container {
  position: relative;
  overflow: hidden;
}
.img-card-container img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.img-card-container img.step2 {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 60px;
}

.modal-create-ticket {
  .ant-row {
    .ant-col {
      .ant-form-item {
        margin: 0;
      }
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .flex-left {
    align-self: center;
  }
  .flex-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-item1 {
      align-self: flex-end;
      .action-right-reasons {
        background-color: rgba(244, 67, 54, 0.8);
        padding: 5px 8px;
        border-radius: 4px;
        // margin-top: 8px;
        // margin-right: 8px;
        border: solid 0.2px #f44336;
        &-list {
          width: 84px;

          flex-grow: 0;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;

          color: #ffffff;
        }
      }
    }
    &-item2 {
      margin-right: 8px;
      align-self: flex-end;
    }
    &-item3 {
      margin-right: 8px;
      align-self: flex-end;
    }
  }
}

.image-upload {
  .ant-upload-list {
    display: none !important;
  }
}
