// Color
$labelColor: #9ea0a5;
$primaryBgColor: #1665d8;
$primaryTextColor: #fff;
$secondartTextColor: #000;
$DropDownborderActive: #1d6de2;

// Size
$labelSize: 12px;
$primaryBtnSize: 14px;

// Button Settings
$primaryBtnBorder: 4px;
$emptyBorder: 1px solid transparent;

// Font Settings
$fontMedium: 500;
$fontSizeForMiniCard: 24px;

//default constants
$white-bg: #ffffff;
$grey-bg: #f9f9fa;
$dark-grey-bg: #ebebeb;
$font-text: #000;
$grey-text: #6f7073;
$light-grey-text: #a5a7af;

$grey-border: #dfe2e5;
$dark-grey-border: #b8bbbe;
$button-grey: #f2f2f5;
$dark-shadow: #dfe2e5;

//information colors
$warning-text: #ff9801;
$warning-border: #ff9801;
$warning-bg: #faeddc;

$success-text: #50b154;
$success-border: #50b154;
$success-bg: #ebf5ea;

$error-text: #f44337;
$error-border: #f44337;
$error-bg: #feecec;

$info-text: #1c6ce1;
$info-border: #1c6ce1;
$info-bg: #e4edfb;

$primary-info-bg: #d4e3fa;
$primary-info-text: #1c6be1b6;
$primary-info-border: #1c6ce1;

$secondary-info-bg: #8080800f;
$secondary-info-text: #808080;
$secondary-info-border: #808080;

$tertiary-info-bg: #b33fb529;
$tertiary-info-text: #b33fb5;
$tertiary-info-border: #b33fb5;
