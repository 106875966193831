.InvoiceCustomerSettlement {
  .Filter {
    .filter-btn {
      width: 100%;
      height: 35px;
      justify-content: space-between;
    }
  }

  .border-bold {
    border: 1px solid #b1b1b1;
  }

  .border-bold-left {
    border-left: 1px solid #b1b1b1;
  }

  .sparkline {
    .highlight {
      font-size: 22px;
    }
  }

  .ant-tag {
    padding: 5px 10px;
  }

  .tag-input {

    width: 200px;
    margin-right: 5px;
    margin-bottom: 5px;
    float: right;

    input {
      background-color: #108ee971;
    }
  }

  .mt-5 {
    margin-top: 20px;
  }

  .sparkline {
    min-width: 100%;
    min-height: 125px;
  }


  .settlement-search-bar {
    width: 100%;
  }


  .img {
    margin-bottom: 10px;
  }

  .search-container {
    display: flex;
    justify-content: space-between;
  }

  .link {
    color: #1665d8;
  }

  .verticle-flex {
    display: flex;
    flex-direction: column;

    .data {
      align-self: center;
      width: 85px;
    }

  }

  .light {
    color: #6b6c6f;
    font-size: 12px;
  }

  .bold {
    font-weight: 600;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .status {
    width: 90px;
    text-align: center;
    border-radius: 4px;
  }

  .status-danger {
    background-color: #e6492d;
    color: white;
    font-size: 12px;
  }

  .status-warning {
    background-color: #f6ab2f;
    color: white;
    font-size: 12px;
  }

  .status-allowed {
    background-color: #34aa44;
    color: white;
    font-size: 12px;
  }

}

.download-annexure {

  .ant-form-item {
    margin-bottom: 5px !important;
  }

  .ant-form-item-label {
    line-height: normal;
  }

  .ant-form-item label {
    color: #9ea0a5 !important;
    font-size: 12px;
    font-weight: 500;
  }

  .ant-input-number,
  .ant-calendar-picker {
    width: 100%;
  }
}
