.download-msg-box {
  padding-top: 10px;
}

.download-box {
  text-align: center;
  width: 60%;
  margin: 28vh auto 65vh auto;
  overflow: hidden;
  
}

.download-box .h4 {
  font-size: 16px;
}

.download-box input {
  display: inline-block;
  width: 80%;
  height: 40px;
  vertical-align: top;
  margin-bottom: 10px;
}

.download-box button {
  display: inline-block;
}
