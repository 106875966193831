.show-add {
    position: absolute;
    top: 10px;
    // bottom: 35px;
    // left: 550px;
    cursor: pointer;
}

.show-minus {
    cursor: pointer;
}