.sidebar-custom {
    transition: all .8s;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    z-index: 99;
    min-height: 640px;
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #0000;
        font-size: 15px;
        font-weight: 600;
        span {
            color: #000;
        }
    }
    .ant-menu-item .anticon+span,
    .ant-menu-submenu-title .anticon+span {
        color: #0000008a;
    }
    .ant-menu-item .anticon {
        font-size: 20px;
    }
    .ant-menu-item>a:hover {
        color: black;
    }
    .ant-menu-item-active {
        font-weight: 600;
        color: black;
    }
    .ant-menu-inline {
        // padding-top: 12px;
    }
    .menu-item-label {
        font-size: 15px;
    }
    .menu-item-img {
        padding: 4px;
        margin-right: 15px;
        color: #fff;
        border-radius: 4px;
        width: 35px;
    }
    .menu-item-container {
        display: flex;
        height: 36px !important;
        line-height: 36px !important;
    }
    .menu-item-container-active {
        border-right: 5px solid #3B5A98;
        img {
            background-color: #3B5A98;
        }
        a {
            font-weight: 600;
        }
    }
    li.ant-menu-item.menu-item-container.menu-item-sub-child {
        display: list-item;
        list-style-type: circle;
        bottom: 14px;
        font-size: 12px;
        padding-top: 10px;
    }
    li.ant-menu-item.menu-item-container.menu-item-sub-child.submenu-selected {
        font-weight: 700; 
    }   
    .ant-menu-submenu-title:hover {
        color: #3B5A98;
    }
    .sidebar-menu-icon {
        font-size: 20px;
    }
   .sidebar-menu-container{
    position: relative;
    margin-top: 15px;
    
    margin-bottom: 10px;
    &-collapse {
        left: 40px;        
    }
    &-opened {
        left: 32px;
    }
   }
   .sidebar-menu-text {
    position: absolute;
    left: 38px;
    top: 10px;
   }
}