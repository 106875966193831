.sla-location_events {
  background-color: #fff;
  
}
.sla-tooltip {
  position: relative;
  display: inline-block;
  &::before {
    content: "";
    position: absolute;
    border: 1px dotted #d9d9d9;
    width: 30px;
    height: 1px;
    top: 9px;
    border-width: 2px;
  }
}
.sla-tooltip_text {
  height: 30px;
  width: 240px;
  background-color: transparent;
  color: #000;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px 0; */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 30px;
  /* margin-left: -60px; */
  border: 2px dotted rgba(40, 40, 40, 0.5);
  padding: 2px;
}
.sla-route {
  .ant-alert-custom-info {
    background: rgba(40, 40, 40, 0.04);
    border: 1px solid #282828;
    height: 40px;
    border-radius: 4px;
  }
  .ant-alert-custom-info,
  span.ant-alert-message {
    color: #282828 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-alert-custom-warning {
    background: #FDFAF4;
    border: 1px solid #d88913;
    height: 40px;
    border-radius: 4px;
  }
  .ant-alert-custom-warning > .ant-alert-message {
    color: #d88913 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-alert-custom-error {
    background: rgba(255, 145, 145, 0.04);
    border: 1px solid #ca0000;
    height: 40px;
    border-radius: 4px;
  
  }
  .ant-alert-custom-error>.ant-alert-message {
    color: #ca0000 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-alert-custom-success {
    background: rgba(0, 133, 86, 0.04);
    border: 1px solid #008556;
    height: 40px;
    border-radius: 4px;
  }
  .ant-alert-custom-success > .ant-alert-message {
    color: #008556 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}

.sla-route-tabs {
  .ant-tabs-nav-container {
    margin-left: 15px;
  }
  .ant-tabs-tab {
    color: #909090;
    // width: 160px;
    // height: 4px;
    // left: 500px;
    // bottom: 572px;

    border-bottom: 4px solid #eaedf3;
    // background: #eaedf3;
    border-radius: 4px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #1665d8;
    background: rgba(22, 101, 216, 0.05);
    border-bottom: 4px solid #1665d8;
    border-radius: 4px;
  }
}
.sla-breached-text {
  color: #CA0000;
  font-weight: 400;
  line-height: 20px;
}
.font-weight-600 {
  font-weight: 600;
}