.image-magnifier {
    width: 350px;
    height: 350px;
    pointer-events: none;
    position: absolute;
    display: none;
    border-width: 4px;
    z-index: 1001;
}

.cursor-zoom-in{
  cursor: zoom-in;
}