.customer-management {
  .ant-select-enabled {
    width: 100% !important;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .pull-right {
    float: right;
  }

  .ant-form-item {
    margin-bottom: inherit;
  }
}
