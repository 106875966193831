.helios-history-tabs div.ant-tabs-tab {
  width: 50%;
}
.helios-history-tabs div.ant-tabs-nav {
  width: 100%;
}
.history-tabs-container {
  .ant-tabs-tab {
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    letter-spacing: 0;
    font-weight: bold;
    border-bottom: unset;
    width: 40%;
    border: 1px sold #000;
  }
  .ant-tabs-ink-bar {
    //width: 50%;
    color: white;
  }
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #3b5a98;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #1665d8;
  }
}
.helios-history-container {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff;
  //height: calc(100vh - 260px);
}
.helios-history-container {
  background-color: #ffffff;
}
.helios-vehicle-title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
}
.helios-arrow-left {
  cursor: pointer;
  float: left;
  padding: 0px 6px 0px 0px;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  position: relative;
  z-index: 100;
  margin-top: 3px;
  margin-right: 5px;
}
.helios-trip-started-date {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000;
  margin-left: 10px;
}
.helios-route {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000;
}
.helios-map-container {
  margin: 0px 30px 15px 15px;
  border-radius: 4px;
}
.helios-date-picker {
  padding: 0px 30px;
}
.helios-range-picker {
  width: 100%;
}
.helios-his-container {
  padding: 5px 30px;
}
.helios-last-trip-list {
  display: inline;
  list-style: none;
  padding: 0px;
}
.helios-trip-data-list {
  list-style: none;
  padding: 0px;
}
.helios-trip-data-list > li {
  display: inline;
}
.helios-last-trip-list > li {
  display: inline;
  margin-right: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #05119e;
}
.trip-list-label {
  opacity: 0.54;
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #100e0e;
}
.helios-border-bottom {
  border-bottom: 1px solid #eaedf3;
}
.helios-right {
  text-align: right;
}
.last-known-location {
  background-color: #091c9b;
  color: #fff;
  font-weight: 500;
}
.last-known-location-trip {
  color: #fff;
  font-weight: 500;
}
.play-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500;
}
.share-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500;
}
.track-card {
  margin: 15px;
}
.track-card-content {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #000000;
}
.helios-history-trip-id {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.11;
  letter-spacing: normal;
  color: #05119e;
  text-align: center;
  margin: 0;
}
.helios-info-container {
  padding: 0 10px;
}
.helios-border-right {
  border-right: 1px solid #7f7f81;
}
.helios-info-value {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}
.tp-speed {
  position: absolute;
  width: 40px !important;
  height: 85px;
  border: 1px solid #ccc;
  top: -82px !important;
  background: #fff;
  right: 15px !important;
}

.helios-divider-border-bottom {
  border-bottom: 1px solid #eaedf3;
}
