.summary_box {
    width: 170px;
    height: 75px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
    -ms-user-select: none;
    user-select: none;
    &-title {
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #a0a1a5;
        &_selected {
            color: #1868dc;
        }
    }
    &-value {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        &_selected {
            color: #1868dc;
        }
    }
    &-selected {
        background-color: #f0f4f9;

        border-radius: 4px;
        border: solid 1px #1868dc;
    }
}