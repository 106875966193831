.waybill-container {
  width: 1260px;
}

.waybill_information {
  label {
    margin-right: 5px
  }

  .card-header {
    font-size: 28px
  }

  textarea {
    width: 100%
  }
}

.mini-box {
  border: 1px solid #eaeaea;
  padding: 10px;
  width: 100%;
  min-width: 100%;
  height: 70px;
  min-height: 70px;
  margin: 10px 0 13px;
  border-radius: 4px;
}

.waybill-right {
  margin-top: 10px
}

.awb-number {
  color: #42587F;
  font-weight: bold;
  font-size: 20px;
}

.waybill_information_mini_info {
  line-height: 24px;
}

.get-info-space {
  padding-top: 10px;
}

.custom-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex
}

.div-table-header {
  background-color: #f3f3f3;
  padding: 10px 10px 10px 8px;
  padding-left: 10px;
  font-weight: bold;
}

.div-table-body {
  border-bottom: 1px solid #eaeaea;
}

.custom-row {
  .box:last-child {
    border-right: 1px solid #eaeaea !important
  }
}

.box {
  flex-grow: 1;
  border: 1px solid #eaeaea;
  padding: 10px;
  border-bottom: 0 !important;
  border-right: 0 !important;

  .shipment-table &:first-child {
    max-width: 117px;
  }

  .delivery-table &:first-child {
    max-width: 118px;
  }

  .charge-table &:first-child {
    max-width: 155px;
  }

  .charge-table &:nth-child(2) {
    max-width: 134px;
  }
}

.waybill_source_destination {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 20px 0;
  padding: 10px 0;
}

.special-instruction {
  margin: 10px 0
}

.image-responsive {
  width: 70%;
}

.mode-info {
  margin-bottom: 10px
}

.booking-info {
  margin: 12px 0 2px
}

/*Move to common*/

.center-structure {
  margin: 0 auto;
}

.opacity-zero {
  opacity: 0
}

.fn-bold {
  font-weight: bold
}

ul.quick_info li {
  font-weight: bold;
  display: inline-block;
  margin: 15px 0;

  .splitter {
    margin: 0 10px
  }
}

.other-info {
  margin-bottom: 10px;

  .info-value {
    color: #716565;
  }
}

.driver-details {
  border-top: 1px solid #42587F;
  border-bottom: 1px solid #42587F;
  padding: 16px 0;
  margin: 24px 0 25px;
}

.more-info-list {
  li {
    border: 1px solid #eaeaea;
    padding: 10px;
    border-radius: 5px
  }
}

.waybill-header-info .print {
  .material-icons {
    position: relative;
    top: 6px;
    cursor: pointer;
  }
}

.inventory_drs_holder {
  width: 500px;
  margin: 0 auto;
  border: 1px solid #42587F;
  border-radius: 5px;
  padding: 20px;
}

.card .card-header.waybill_heading span {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.info-left {
  width: 250px;
  display: inline-block;
}

.info-right {
  width: 180px;
  display: inline-block;
}

.card-mini-header {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.border-bottom-box {
  flex-grow: 1;
  border-bottom: 1px solid black;
}

.column-flex {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
}

.border-around {
  border: 1px solid black;
}

.border-left-only {
  border-left: 1px solid black;
}

.transparent-right-border {
  border-right-color: transparent;
}

.transparent-bottom-border {
  border-bottom-color: transparent;
}

.padding-10 {
  padding: 10px !important;
}

.no-padding {
  padding: 0;
}

.lead-information p {
  margin-bottom: 5px;
}

.lead-information hr {
  border-color: black;
  border-style: dashed;
  border-width: 1px;
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.page-breaker {
  page-break-after: always
}

.text-center {
  text-align: center
}

.table-runsheet,
.th-runsheet,
.td-runsheet {
  border: 1px solid black;
  border-collapse: collapse;
}

.th-runsheet,
.td-runsheet {
  padding: 15px;
  text-align: left;
}

.no-top-border {
    border-top: none;
}
.no-bottom-border {
    border-bottom: none;
}

.docket-template-wrapper {
  max-width: 100vw;
  & .waybill-template {
    max-width: 49%;
    line-height: 1.25;
    color: #000;
  }
}

.multiple-challans .docket-template-wrapper{
  margin-bottom: 50px;
}