.pod-spin{
    padding-bottom: 25px;
}
.pod-buttons{
    button{
        width: 120px;
        border: none;
    }
    button.verify-button, button.verify-button:active, button.verify-button.active{
        background-color: #01a870;
        border-color: #01a870;
        color: #FFFFFF;
    }
    button.verify-button:hover{
        background-color: #03dd94;
        border-color: #03dd94;
        color: #FFFFFF;
    }
    button.reject-button, button.reject-button:active, button.reject-button.active{
        background-color: #f44336;
        border-color: #f44336;
        color: #FFFFFF;
    }
    button.reject-button:hover{
        background-color: #fa695e;
        border-color: #fa695e;
        color: #FFFFFF;
    }
}

.deps-images-wrapper{
    height: 55px;
    width: 55px;
    border: 2px solid #1c6ce1;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    img{
        width: 51px;
        height: 51px;
    }
    .delete-icon{
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 14px;
        cursor: pointer;
        padding: 2px;
        border-radius: 50%;
        color: #ffffff;
        background-color: #ff0000;
    }
    &.updated-deps{
      border-color: #1c6ce1;
      border-width: 4px;
      img{
        width: 47px;
        height: 47px;
      }
    }
    &.upload-icon span{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ant-upload-list-item{
            display: none;
        }
    }
    .ant-upload{
        img{
            width: auto;
            height: auto;
        }
    }
}

.deps-ant-button button.ant-btn-circle, .deps-ant-button.ant-btn-circle, .deps-ant-button.ant-btn-circle:hover, .deps-ant-button.ant-btn-circle:focus, .deps-ant-button.ant-btn-circle:active{
    background-color: transparent;
    border: none;
    outline: none;
}
.deps-ant-button button.ant-btn-circle, .deps-ant-button.ant-btn-circle:disabled{
    opacity: 0.5;
}
.pod-history-event .clearfix{
    color: #2568c8;
    font-weight: bold;
}