.image-gallery{
    .gallery-wrapper{
        width: 100%;
        overflow: hidden;
        position: relative;
        .gallery-slider{
            white-space: nowrap;
            position: relative;
            transition: ease-out 0.25s;
            left: 0%;
            .image-wrapper{
                display: inline-block;
                width: 100%;
            }
        }
        .nav-arrows {
            position: absolute;
            top: 50%;
            z-index: 2;
            &.right-nav{
                right: 5px;
            }
            &.left-nav{
                left: 5px;
            }
        }
        .image-action-tools{
            position: absolute;
            bottom: 5px;
            right: 10px;
            background-color: #8D8D8D;
            button{
                background: transparent;
                border: none;
                color: #ffffff;
                font-size: 20px;
            }
        }
    }
    .thumbnail-panel{
        width: 100%;
        overflow: auto;
        margin-top: 25px;
        .thumbnail-image{
            cursor: pointer;
        }
        .selected-image{
            transform: scale(1.25);
            transition: linear 0.1s;
            position: relative;
            .delete-icon{
                position: absolute;
                bottom: 2px;
                right: 2px;
                padding: 2px;
                border-radius: 50%;
                color: #ffffff;
                background-color: #ff0000;
            }
        }
    }
    .nav-arrows {
        color: #ffffff;
        font-size: 25px;
        background-color: #8D8D8D;
        height: 35px;
        width: 35px;
        padding: 0px;
    }
}