.sla-listing-container {
  .sla-listing-container_filters {
    box-shadow: 0px 2px 4px rgba(110, 112, 115, 0.2);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
}
