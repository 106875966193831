.BulkOperation {
  a {
    color: #40a9ff !important;
  }

  .lite-option {
    padding: 15px 0 !important;
  }

  .text-error {
    color: red;
  }

  .file-disable-loader {
    background-color: #000000a6;
  }
}
