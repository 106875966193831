.tabspane-custom {
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    font-weight: normal;
    min-height: 58px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #1665d8;
  }
}
