.ant-btn-primary{
    background-color: rgba(59, 90, 152, 0.9) !important;
    border-color: rgba(59, 90, 152, 0.9) !important;
}
// .ant-btn-danger{
//     background-color: #ffa39e !important;
//     border-color: #ff7875 !important;
// }
.ant-btn-success{
    background-color: #95de64 !important;
    border-color: #73d13d !important;
}
.ant-btn-info{
    background-color: #fff566 !important;
    border-color: #ffec3d !important;
}