.Header {
  display: flex;
  min-height: 70px;
  background-color: #3b5a98;
  justify-content: space-between;

  .logo-img {
    width: 12em;
    height: 48%;
    vertical-align: middle;
    margin: 1.2em .5em;
  }

  .lhs {
    object-fit: fill;
  }

  .rhs {
    display: flex;

    .waybill-track {
      display: inherit;
      width: auto;
      min-width: 140px;
      margin-right: 12px;
      margin-top: 20px;
      height: 32px;
    }
  }

  .waybill-track {
    margin: 7px 10px;
    height: 30px;

    input {
      border-radius: 5px 0px 0px 5px;
      padding: 9px;
      height: 32px;
      margin-right: 0px;
      border-width: 0;
    }

    button {
      border-radius: 0px 5px 5px 0px;
      border-width: 0;
      border-left: 1px solid;
      cursor: pointer;
    }
  }

  .img-size {
    width: 17em;
    height: 70px;
  }

  .hub-modal {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-header {
          background: #3b5a98 !important;
          color: #fff !important
        }
      }
    }

  }

}
