.orangecirclebox {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px orange;
  margin: 0px 18px -1px -7px;
  // animation: blinker 1s linear infinite;
}
.lineset5 {
  border: 1px solid grey;
  border-style: dotted;
  height: 70px;
  width: 0px;
}
.greencircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px green;
  margin: 0px 18px -1px -7px;
}
.top-mar-btn {
    margin-top: 18px;
}
.no-data-display {
   text-align: center;
   font-weight: 600;
   font-size: 20px;
}
.sno{
  font-weight: bold;
  color: black;
  font-size: 12px;
}
