.Steps{
    .ant-steps{
        width: 70%;
        margin: 0 auto;
    }
    .ant-steps-dot .ant-steps-item-tail {
        width: 115% !important;
        top: 15px !important;
        margin: 0 0px 0px 72px !important;
    }
    .ant-steps-dot .ant-steps-item-icon{
        width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
    }
}