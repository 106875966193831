.drs {
  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }
}

.b2c-form {
  .element-splitter {
    background-color: #eaeaea;
    border-radius: 50%;
    padding: 8px;
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
  }
}
