@import './buttons.scss';

.god-select{

}

@mixin cardMiniHeader($property) {
    font-size: $fontSizeForMiniCard;
    font-weight: $fontMedium;
    min-height: 74px;
    padding: 19px 0;
}