.container {
  position: relative;
  width: 100%;
  max-width: 400px;
}


.container .img-pod {
    position: absolute;
    /* top: 50%; */
    left: 60%;
    /* -webkit-transform: translate(0, 0); */
    /* transform: translate(-50%, -50%); */
    // -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: white;
    font-size: 25px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    /* text-align: center; */
}


.container .img-pod:hover {
   background: transparent;
   border: none;
   stroke: none;
}
.container .img-pod:active {
    background: transparent;
    border: none;
    stroke: none;
 }
