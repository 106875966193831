@import "_variables";

button {
  white-space: nowrap;
}

@mixin primaryButton($property) {
  font-size: $primaryBtnSize;
  border-radius: $primaryBtnBorder;
  background-color: rgba(59, 90, 152, 0.9);
  color: $primaryTextColor;
  
}

@mixin transparentButton($property) {
  font-size: $primaryBtnSize;
  border-radius: $primaryBtnBorder;
  background-color: $emptyBorder;
  color: $secondartTextColor;
  font-weight: $fontMedium;
}

.primary-btn {
  @include primaryButton(none);

  border: none;
  width: auto;
  
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  &:hover,
  &:active,
  &:focus {
    background-color: $primaryBgColor;
    color: white;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, rgb(22, 101, 216), rgb(31, 111, 229));
    opacity: 0.5;
  }
}

.ant-btn.lsn-btn-primary, .lsn-btn-primary,
.ant-btn.lsn-btn-primary:active, .lsn-btn-primary:active,
.ant-btn.lsn-btn-primary:focus, .lsn-btn-primary:focus,
.ant-btn.lsn-btn-primary:hover, .lsn-btn-primary:hover {
  background: linear-gradient(0deg, $primaryBgColor 2.22%, #1f6fe5 98.44%);
  box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
  border-radius: 4px;
  color: $white-bg;
  margin: 0px;
}
.ant-btn.lsn-btn-primary:disabled {
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-color: #1b6be0;
  color: $white-bg;
  opacity: 0.54;
}

.ant-btn.lsn-btn-secondary,
.ant-btn.lsn-btn-secondary:active,
.ant-btn.lsn-btn-secondary:focus,
.ant-btn.lsn-btn-secondary:hover {
  background: $white-bg;
  box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
  border-radius: 4px;
  color: #000;
  margin: 0px;
}
.lsn-btn-secondary:disabled {
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-color: $white-bg;
  color: #000;
  opacity: 0.54;
}

.ant-btn.lsn-btn-success,
.ant-btn.lsn-btn-success:active,
.ant-btn.lsn-btn-success:hover {
  background: #61ce79;
  border-radius: 4px;
  color: #ffffff;
  margin: 0px;
}
.lsn-btn-success:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn.lsn-btn-error,
.ant-btn.lsn-btn-error:active,
.ant-btn.lsn-btn-error:hover {
  background: #ed6347;
  border-radius: 4px;
  color: #ffffff;
  margin: 0px;
}
.lsn-btn-error:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.transparent-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  vertical-align: top;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #000;
  }
}

.secondary-btn {
  border: none;
  width: auto;
  height: 32px;
  padding: 5px 12px;
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #fff;
  vertical-align: text-top;
  margin: 0px;
  // &:hover,
  // &:active,
  // &:focus {
  //   background-color: transparent;
  //   color: #000;
  // }
}

.warning-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #ed6347;
  background-color: #ed6347;
  vertical-align: text-top;
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    background-color: #ed6347;
  }
}

.activity-btn {
  background-color: #f2f2f5;
  color: #000;
  padding: 5px 15px;
  vertical-align: text-top;
  border-color: #e7e7e8;
  vertical-align: text-top;

  &:hover,
  &:active,
  &:focus {
    background-color: #f2f2f5;
    color: #000;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, rgb(22, 101, 216), rgb(31, 111, 229));
    opacity: 0.5;
  }
}

.close-btn {
  background-color: grey;
  color: #fff;
  padding: 8px 19px;
  border-radius: 6px;
  vertical-align: text-top;
  &:hover,
  &:active,
  &:focus {
    background-color: $primaryBgColor;
    color: white;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, rgb(22, 101, 216), rgb(31, 111, 229));
    opacity: 0.5;
  }
}

.load-share-custom {
  .ant-select-selection--single {
    vertical-align: middle;
    min-height: 38px;
    padding-top: 3px;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: $DropDownborderActive;
    box-shadow: none;
  }
  .ant-modal-body {
    line-height: 2;
  }
  .ant-modal-footer {
    padding: 18px 20px;
  }
}

.select-dropdown {
  .ant-select-dropdown-menu-item {
    border-bottom: 1px solid #eaeaea;
    padding: 8px 12px;
  }
}

.remit-modal {
  .ant-select {
    width: 100%;
  }
}

.btn-group-action {
  button {
    margin-left: 10px;
  }
}

.button-properties {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.seconadary-grey-button {
  @extend .button-properties;
  background-color: $button-grey;
  &:hover {
    border: 1px solid $dark-grey-border;
    box-shadow: 0px 0px 0px 2px $dark-shadow;
  }
}

.cursor-pointer:disabled {
  cursor: not-allowed !important;
}

.action-button {
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  background-color: $grey-bg;
  border: 1px solid $grey-border;
  border-radius: 5px;
}
.action-menu {
  min-width: 125px;
}
