.inventory-drs-info {
  width: 500px;
  margin: 0 auto;
  border: 1px solid #42587F;
  border-radius: 5px;
  padding: 20px;

  .waybill-header-info {
    .print {
      float: right;

      i {
        position: relative;
        top: 6px;
        cursor: pointer;
      }

    }

    .status {
      margin-top: 5px;
      float: right;
    }

    .awb-number {
      color: #42587F;
      font-weight: bold;
      font-size: 20px;
    }

    ul.quick-info {
      padding: 0 !important;

      li {
        font-weight: bold;
        display: inline-block;
        margin: 15px 0;

        .splitter {
          margin: 0 10px
        }
      }
    }
  }

  .more-info-list {
    padding-left: 0px;
    list-style: none;
    li {
      border: 1px solid #eaeaea;
      padding: 10px;
      border-radius: 5px
    }

    .destination {
      float: right;
    }
  }
}
