.sparkline {
  min-width: 269px;
  min-height: 112px;
  border-radius: 4px;
  border: solid 1px #eaedf3;
  margin-right: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 10px 10px 10px 15px;
  display: inline-block;
  vertical-align: top;

  .highlight {
    font-size: 30px;
    color: #000;
    margin-top: 35px;
  }

  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }

  .title {
    color: #a0a1a5;
    font-weight: 100;
    text-transform: uppercase;
    text-align: left;
  }
}
