.TrackingDetails {
    .tracking-details-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .Card {
        margin-top: 30px;
    }

    .border-top-light {
        border-top: 1px solid lightgray;
    }

    .padding-24-top-bottom {
        padding: 24px 0;
    }

    .search-bar {
        input{
            width: 220px;
        }
        &.ant-input-affix-wrapper .ant-input-suffix {
            right: 0px;
        }
    }

    .download-btn {
        border: solid 1px rgba(0, 0, 0, 0.12);
        background-color: rgba(98, 2, 238, 0);
        margin-right: 10px;
        color: #3b5a98;
    }

    .large-col {
        max-width: 200px !important;
        overflow-wrap: break-word;
        white-space: normal;

        p {
            overflow-wrap: break-word;
            white-space: normal;
        }
    }

    .small-col {
        max-width: 100px !important;
        overflow-wrap: break-word;
        white-space: normal;

        p {
            overflow-wrap: break-word;
            white-space: normal;
        }
    }

    .small-text {
        font-size: 12px;
        color: darkgray;
    }
}