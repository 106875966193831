.ant-vendor-tooltip {
    font-size: 9px !important;

    .ant-tooltip-content {
        .ant-tooltip-inner{
            min-height: 10px !important;
        }
    }
}
.vendor-view-more {
    margin-top: 10px;
    float: right;
    text-decoration: underline;
    color: #096dd9;
}

.vendor-view-more:active, .vendor-view-more:hover {
    float: right;
    text-decoration: underline;
    color: #096dd9;
}