.marker-cluster-custom {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid  rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-green {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-red {
  background: red;
  border: 5px solid #ededed;
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.bg-black{
  background-color: black;
  color: #ffffff;
}
.bg-red {
  background-color: red;
  color: #ffffff;
}
.bg-green {
  background-color: green;
  color: #ffffff;
}
.bg-yellow {
  background-color: yellow;
  color: #ffffff;
}
.truck-icon {
  height: 0px !important;
}
.truck-icon1 {
  height: 0px !important;
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.boxgooglemap{
	width: 100%;
	height: 630px;
}
.map-label{
  position: absolute;
  margin-left: -7px;
  margin-top: 15px;
  /* transform: rotate(360deg); */
  font-weight: 900;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000;
}
.fg-red {
  color: tomato !important;
}

.fg-dark-red {
  color: red !important;
}

.fg-blue {
  color: dodgerblue !important;
}

.fg-green {
  color: mediumseagreen !important;
}

.fg-orange {
  color: orange !important;
}

.fg-grey {
  color: grey !important;
}

.fg-light-grey {
  color: #d3d3d3 !important;
}

.fg-yellow {
  color: gold !important;
}

.fg-light-green {
  color: #7CFC00 !important;
}

.fg-white {
  color: white !important;
}

.fg-black {
  color: black !important;
}

.fg-pink {
  color: deeppink !important;
}
.bg-red {
  background-color: tomato !important;
}

.bg-dark-red {
  background-color: red !important;
}

.bg-blue {
  background-color: dodgerblue !important;
}

.bg-green {
  background-color: mediumseagreen !important;
}

.bg-orange {
  background-color: orange !important;
}

.bg-grey {
  background-color: grey !important;
}

.bg-black {
  background-color: black !important;
}

.bg-yellow {
  background-color: gold !important;
}

.bg-light-green {
  background-color: #7CFC00 !important;
}

.bg-light-grey {
  background-color: #d3d3d3 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-pink {
  background-color: deeppink !important;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 2px;
  min-width: 30px;
}
.fleet-home-iw label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.markercluster-map { height: 90vh; }
.svg-red svg {
  fill: tomato;
}

.svg-dark-red svg {
  fill: red;
}

.svg-blue svg {
  fill: dodgerblue;
}

.svg-green svg {
  fill: mediumseagreen;
}

.svg-orange svg {
  fill: orange;
}

.svg-grey svg {
  fill: grey;
}

.svg-black svg {
  fill: black;
}

.svg-yellow svg {
  fill: gold;
}

.svg-light-green svg {
  fill: #7CFC00;
}

.svg-white svg {
  fill: white;
}

.svg-pink svg {
  fill: deeppink;
}
.leaflet-marker-icon-wrapper {
  margin-left: -12px;
  margin-top: -11px;
}
.leaflet-cluster-popup {
  max-height: 100px;
  overflow-y: scroll;
}
.map-label-with-text-shadow {
  position: absolute;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000;
}
.helios-select {
  width: 200px;
  margin-bottom: 5px;
}
.helios-filters label {
  color: #9ea0a5;
}
.helios-tracking-title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
}
.refresh-helios{
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500;
}
.helios-search {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  height: 40px;
}
.helios-list-view {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  border: solid 1px #1767da;
  color:#1968dc;
  font-weight:500;
  margin-right: 15px;
}
.helios-download {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-image: linear-gradient(to top, #1665d8, #1f6fe5);
  color: #ffffff;
  font-weight:500;
}
.helios-row {
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff;
}
.helios-radio-group {
  margin-top: 35px;
  float: right;
}
.helios-on-radio-btn {
  margin: 0 5px;
  border-radius: 4px !important;
  font-weight: 500;
}
.helios-off-radio-btn {
  border-radius: 4px !important;
  font-weight: 500;
}
.helios-map-column {
  padding-left: 12px;
}
.helios-list-column {
  padding: 16px;
  background-color: #ffffff;
  min-height: 600px;
  height: 600px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: -2px -1px 3px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
.helios-vehicle-card {
  min-height: 200px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 10px;
}
.helios-vehicle-num {
  font-weight: 500;
  float: left;
  color: #000;
  cursor: pointer;
}
.helios-vehicle-num:hover{
  color: blue;
}
.helios-track-type {
  color: #e86825;
  background-color: #ffe1be;
  float: right;
  border-radius: 3px;
  margin-right: 15px;
  padding: 1px 5px;
}
.helio-trip-status {
  float: right;
  color: #000;
}
.helio-trip-status-icon-on {
    float: left;
    width: 10px;
    height: 10px;
    background-color: #2d9d78;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 5px;
}
.helio-trip-status-icon-off {
  float: left;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
}
.helios-card-row {
  /* margin-bottom: 12px; */
}
.helios-label {
  font-size: 10px;
  color: #100e0e;
  opacity: 0.54;
}
.helios-label-value {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #353535;
}
.helios-right{
  text-align: end;
}
.helios-card-bottom-row {
  border-top: 1px solid #eaedf3;
}
.helios-vehicle-status {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353535;
  margin-top: 15px;
  margin-bottom: 0;
}
.helios-vehicle-ignition {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353535;
  margin-top: 15px;
  margin-bottom: 0;
}
.helios-call-icon {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 1px #dfdfdf;
  padding: 4px;
}
.helios-search-type {
  margin: 5px;
}
.location-control-btn {
  padding: 6px 14px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  border: solid 1px #b4b4b4;
  background-color: #ffffff;
  margin: 0 10px;
}
.status-control-btn {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-color: #ffffff;
  margin: 0 8px;
  border: none;
}
.layer-all {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #1065e1;
}
.helios-trip-id {
  cursor: pointer;
}
