@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
body * {
  font-family: "Roboto", sans-serif;
  // height: 100%;
}

.Header {
    display: flex;
    min-height: 70px;
    background-color: #3b5a98;
    justify-content: space-between;
    .logo-img {
        width: 12em;
        height: 48%;
        vertical-align: middle;
        margin-left: 6px;
        margin-top: 20px;
    }
    .rhs {
        display: flex;
    }
}

.App {
  // height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  .icon {
    line-height: 64px;
    color: #fff;
    font-size: 23px; // margin-left: 4px;
    cursor: pointer;
    top: 7px;
    position: relative; // margin-right: 12px;
    font-size: 22px;
    padding: 5px 9px;
  }
  .padding-container {
    padding: 0 1.8rem;
  }
  .tabs-container {
    min-height: 80px;
    max-height: 80px;
    .ant-tabs-tab {
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
      letter-spacing: 0;
      font-weight: bold;
      border-bottom: unset;
    }
    .ant-tabs-ink-bar {
      background-color: #3b5a98;
      height: 4px;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #3b5a98;
    }
  }
  .btn-danger {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
    background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
    color: white;
    margin-right: 10px;
  }
  .btn {
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    margin-right: 10px;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 15px 16px;
  font-weight: normal;
}
tr.ant-table-expanded-row td,
tr.ant-table-expanded-row:hover td {
  background: #ebeff2;
}
.childTable {
  tbody.ant-table-thead > tr > th {
    background: #f8f9fa;
  }
  tbody.ant-table-tbody > tr:nth-child(odd) > td {
    background-color: #fff;
  }
  tbody.ant-table-tbody > tr:nth-child(even) > td {
    background-color: rgba(246, 246, 248, 0.7);
  }
}

.right {
  float: right;
}

.header-select-drop.ant-select.ant-select {
  display: inline-block;
  width: auto;
  min-width: 140px;
  margin-right: 12px;
  // margin-top: 20px;
}

.name-splitter {
  margin: 0 5px;
}

.light-text {
  color: gray !important;
}
.bold {
  font-weight: 600 !important;
}
.form-icon {
  width: 20px;
  height: 20px;
  font-size: 17px;
  margin-top: 10px;
  cursor: pointer;
}

.blue {
  color: #0336ff;
}

.flex{
  display: flex;
}
.justify-space-around{
  justify-content: space-around;
}
.flex-column{
  flex-direction: column;
}
.width-50{
  width: 50%;
}
.width-45{
  width: 45%
}
.Card{
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  align-self: center;
}
.small-text{
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #97a0af;
}
.bold-small-text{
  font-weight: bold;
  line-height: 1.57;
}
.margin-left-10{
  margin-left: 10px;
}
.margin-right-10{
  margin-right: 20px;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left;
}

.background-default {
  background-color: #e0e0e0;
  color: #fff;
}

.background-primary {
  background-color: #4680ff;
  color: #fff;
}

.background-success {
  background-color: #93BE52;
  color: #fff;
}

.background-info {
  background-color: #62d1f3;
  color: #fff;
}

.background-warning {
  background-color: #FFB64D;
  color: #fff;
}

.background-danger {
  background-color: #FC6180;
  color: #fff;
}
