// .zone-mapping-read-only.contract-zone-mapping {
//     li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
//     li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
//     li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
//         color: #000;
//     }
//     li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher) {
//         i, svg{
//             display: none;
//         }
//     }
//     .zone-mapping {
//         .ant-row.ant-form-item:first-of-type {
//             display: none;
//         }
//     }
// }

// .ant-card.zone-mapping .ant-spin-nested-loading .ant-spin-dot.ant-spin-dot-spin {
//     visibility: hidden;
// }
.zone-error {
    color: #f5222d;
}
.zone-modal {
    .lsn-btn-primary,
    .lsn-btn-primary:active,
    .lsn-btn-primary:hover {
        background: linear-gradient(0deg, #1665d8 2.22%, #1f6fe5 98.44%);
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
        border-radius: 4px;
        color: #ffffff;
    }
}
.contract-zone-mapping {
    position: relative;
}
.pincode-error {
    position: absolute;
    bottom: 25px;
    left: 25px;
}
