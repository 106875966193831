.checkbox-ul {
  max-height: 314px;
  width: 346px;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  .ant-menu-item {
    // margin-bottom: 0 !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .sla-menu-item {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
    // margin-top: 2px;
  }
}
.menu-divider {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.sla-action-apply,
.sla-action-apply:hover,
.sla-action-apply:active
 {
  background: #1461d2;
  box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
  border-radius: 4px;
  color: #fff;
}
