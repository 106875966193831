.Tracking {
  display: flex;
  flex-direction: column;
  .Card {
    width: 100%;
  }

  .gutter {
    margin: 10px;
  }

  .smallMessage {
    width: 95%;
    margin: 0;
  }

  .error-messege {
    color: red;
  }

  .tracking-primary-btn {
    border-radius: 4px;
    background-color: rgba(59, 90, 152, 0.9);
    color: white;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(59, 90, 152, 0.9) !important;
      color: white !important;
    }

    .track-icon {
      padding: 0 5px;
      width: 30px;
      height: 25px;
    }
  }

  .waybills-input {
    width: 75%;
    margin-right: -1px;
    max-height: 100px !important;
    overflow-y: auto;
    border-radius: 0;
  }
}

.tracking-map {
  .boxapprox {
    height: initial;
  }

  .leaflet-container {
    height: 20vw;
  }
}

.Route-Activity {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  padding: 16px 24px;
}

.route-card {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff;
}

.divider {
  height: 1px;
  background-color: #eaedf3;
}

.vehicle_list {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  padding: 12px 24px;
}

.pt_10 {
  padding-top: 10px;
}

.start-time {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #9ea0a5;
}

.start_date {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}

.detail_box {
  float: left;
  padding: 8px 14px;
}

.br-l {
  border-left: 1px solid #7f7f8126;
}

.br-r {
  border-right: 1px solid #7f7f81;
}

.tripdetails {
  padding: 5px 0px 55px 0px;
}

.tripdetails2 {
  padding: 5px 0px 5px 0px;
}

.ticker-layer {
  width: 10px;
  height: 10px;
  border-radius: 8px;
  border: solid 3px #405dfe;
  background-color: #ffffff;
}

.current_location {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #091c9b;
  padding: 0px 10px 0px 30px;
}

.stepper {
  margin: 20px 0px;
  height: 500px;
  overflow: scroll;
  padding: 0px 20px;
}

.bluebase {
  width: 16px;
  height: 16px;
  background-color: #091c9b;
  border-radius: 50%;
  color: #fff;
  line-height: 1.6;
  font-weight: 500;
  font-size: 10px;
}
.litebutton {
  margin: 0px -4px;
}

.whitebase {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  background-color: #ffffff;
}

.dateSet {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.52);
  padding: 1px 0px;
}

.hiddenHalts {
  display: none;
}

.showHalts {
  display: block;
}

.step-title {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
  float: left;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.step-title2 {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
}

.top-row {
  padding-bottom: 20px;
}

.delays {
  float: right;
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: right;
  color: #e53935;
}

.iconAdj {
  color: #1665d8;
  font-weight: 900;
  font-size: 8px;
}

.bluecircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  margin: 0px 18px -1px -7px;
}

.blueboxcircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  margin: 0px 18px -1px -8px;
  background: #405dfe;
}

.redcircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px red;
  margin: 0px 18px -1px -8px;
}

.greyCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #999;
  margin: 0px 18px -1px -8px;
}

.lineset {
  border: 1px solid #405dfe;
  height: 70px;
  width: 0px;
}

.lineset4 {
  border: 1px solid #405dfe;
  border-style: dotted;
  height: 70px;
  width: 0px;
}

.lineset3 {
  border: 1px solid #405dfe;
  height: 65px;
  width: 0px;
}

.lineset2 {
  border: 1px solid #fff;
  height: 70px;
  width: 0px;
}

.left_box {
  float: left;
}

.clearing {
  clear: both;
}

.whiteLetter {
  color: #fff;
  line-height: 1;
  font-size: 11px;
  margin: 0px 2px;
  font-weight: 500;
}

.greyBackground {
  border-radius: 4px;
  background-color: #f3f3f3;
}

.buttondashed {
  margin: 6px 0px;
  font-family: Roboto;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #054ada;
  height: 20px !important;
}

.truncate {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.floting {
  float: left;
}

.dateSet.datime {
  float: right;
  padding: 0px 10px 0px 0px;
}

.currentPosition {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1665d8;
}

.bluecirclebox {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  margin: 0px 18px -1px -7px;
  animation: blinker 1s linear infinite;
}

.blink_me {
  width: 10px;
  height: 10px;
  margin: 5px 5px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  background-color: #ffffff;
  animation: blinker 1s linear infinite;
  float: left;
  margin: 6px 8px 20px 12px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.vehicleTitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  float: left;
  margin-right: 20px;
}

.vehicleDes {
  opacity: 0.87;
  padding: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000;
}

.arrowLeft {
  cursor: pointer;
  float: left;
  padding: 0px 6px 0px 0px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  position: relative;
  z-index: 100;
}

.buttongroup {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px;
  // justify-content: space-between;
}

.buttongroup button.topBt {
  margin-left: 10px;
}

.pt_20 {
  padding-top: 20px;
}

// textarea.lsn-textarea {
//   border: 0px;
//   height: 16px;
//   width: 108px;
//   resize: none;
//   font-size: 13px;
//   font-weight: 700;
// }
// .lsn-textarea:focus{
//   border: 0px !important;
//   height: 16px;
//   width: 108px;
//   resize: none;
//   font-size: 13px;
//   font-weight: 700;
// }

.grren {
  color: #1890ff;
}

.invoice-rows {
  cursor: pointer;
}

.table-row-light {
  cursor: pointer;
  background-color: #a2d0fa !important;
}
.go-back {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  background-color: #e6e6ea;
}
.tracking-input input {
  border-radius: 4px 0 0 4px;
}

.tracking-share {
  float: right;
  position: relative;
  bottom: 25px;
  left: 30px;
  .share-button {
    width: 328px;
    height: 48px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #1a6ade;
    &:hover,
    &:focus {
      width: 328px;
      height: 48px;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      background-color: #1a6ade;
    }
    span {
      width: 248px;
      // height: 19px;
      font-family: Roboto;
      font-size: 16px;
      // font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
}

.share-modal-title {
  width: 245px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.share-modal-subtitle {
  width: 252px;
  opacity: 0.54;
  margin-top: 17px;
  margin-bottom: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.share-modal-input {
  width: 285px;
  border-radius: 4px;
  background-color: #ffffff;
  height: 40px;
}

.share-copy-primary {
  color: #fff;
  width: 40px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #0070eb;
}

.share-copy-green {
  color: #fff;
  width: 40px;
  height: 32px;
  border-radius: 4px;
  background-color: #008851;
}

.cursor-default {
  cursor: initial !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
