.u-font-bold {
    font-weight: 500;
}

.font-size-l-1 {
    font-size: 18px;
}

.font-size-sm-2 {
    font-size: 11px;
}

.font-size-m-1 {
    font-size: 12px;
}

.font-size-m-2 {
    font-size: 14px;
}

.font-size-m-3 {
    font-size: 16px;
}

.font-size-sm-1 {
    font-size: 10px;
}

.font-size-header {
    font-size: 26px;
}

.text-align-center {
    text-align: center;
}

.spacer-xs {
    padding: 5px;
}
.spacer-s {
    padding: 10px;
}
.spacer-m {
    padding: 15px;
}
.spacer-l {
    padding: 25px;
}

.spacer-hl {
    padding: 0px 25px;
}
.spacer-vl {
    padding: 25px 0px;
}

.spacer-hm {
    padding: 0px 15px;
}
.spacer-vm {
    padding: 15px 0px;
}

.spacer-hs {
    padding: 0px 10px;
}
.spacer-vs {
    padding: 10px 0px;
}
.spacer-vxs {
    padding: 5px 0px;
}

.white-bg {
    background-color: $white-bg;
}
.grey-bg {
    background-color: $grey-bg;
}
.dark-grey-bg {
    background-color: $dark-grey-bg;
}
.font-text {
    color: $font-text;
}

.border-grey {
    border: 1px solid $grey-border;
}

.border-radius-m {
    border-radius: 10px;
}
.border-radius-s {
    border-radius: 5px;
}

.text-capitalize {
    text-transform: capitalize;
}
.width-half {
    width: 50%;
}
.half-flex-basis {
    flex-basis: 50%;
}
.text-decoration-underline {
    text-decoration: underline;
}