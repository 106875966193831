.Ingest {
    .margin-top-15 {
        margin-top: 15px;
    }
    .ant-form-item-label > label {
        
    }

    .ant-input-number {
        width: 100%
    }
}