.input-margin-lr {
    margin-left: 2px;
    margin-right: 2px;
}

.mius-button {
    margin-left: 45px;
    margin-top: 50px;
}

.mius-button2{
    margin-top: 10px;
    margin-left: 45px;
}

.t-button{
    margin-top: 50px;
}

.t-button2{
    margin-top: 10px;
}

.ant-divider.ant-divider-horizontal.custom_divder {
    margin: 40px -43px 10px -47px !important;
    width: 108%;
}

.customCard_body .ant-card-body{
    padding-bottom: 0px !important;
}

.input-base-margin-lr{
    margin-left: 14px !important;
}