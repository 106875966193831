.partb-list {
    &-search,
    &-search:active,
    &-search:focus,
    &-search:visited,
    &-search:hover {
        background-color: #1868dc !important;
        color: #ffffff !important;
    }
    &-search:disabled {
        background-color: rgba(0, 0, 0, 0.24) !important;
        color: #ffffff !important;
    }
    &-empty {
        flex-grow: 0;
        margin: 0 0 0 12px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #354052;
    }
    &-success,
    &-success:hover,
    &-success:focus,
    &-success:visited,
    &-success:active {
        // margin: 16px 0 0 16px;
        // padding: 9px 27.5px 11px 26.5px;
        border-radius: 4px !important;
        box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
        background-color: #01a870 !important;
        color: #ffffff !important;
    }
    &-success:disabled {
        background-color: rgba(0, 0, 0, 0.24) !important;
        color: #ffffff !important;
    }
}
