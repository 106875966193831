th.customHead {
    font-size: 9px;
}

.green{
    color: green;
    font-weight: 900;
}

.red{
    color: red;
    font-weight: 900;
}

.draftNumber{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.addressFont{
    font-size: 9px !important;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  
  #components-table-demo-drag-sorting tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }

.draftInfo{
    width: 18.4%;
}
