.sla-breahced_listing {
  &-box {
    height: 66px;
    border: 1px solid #dfe2e5;
    border-radius: 4px;
    width: 343px;
    &-breached {
      background: rgba(202, 0, 0, 0.04);
      border: 1px solid #ca0000;
    }
    &-at_risk {
      background: #fdfaf4;
      border: 1px solid #d88913;
    }
    &-on_time {
      background: #f2fbf8;
      border: 1px solid #008556;
    }
  }
  &-value {
    font-weight: 500;
    &-breached {
      color: #ca0000;
    }
    &-at_risk {
      color: #d88913;
    }
    &-on_time {
      color: #008556;
    }
  }
}
.sla-alerts {
  .ant-alert-alert-error {
    background: #fdf5f5;

    height: 35px;
    border-radius: 4px;
  }
  .ant-alert-alert-error > .ant-alert-message {
    color: #ca0000 !important;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-alert-alert-warning {
    background: #fdfaf4;
    height: 35px;
    border-radius: 4px;
  }
  .ant-alert-alert-warning > .ant-alert-message {
    color: #d88913 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-alert-alert-success {
    background: #F2FBF8;    
    height: 35px;
    border-radius: 4px;
  }
  .ant-alert-alert-success > .ant-alert-message {
    color: #008556 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
