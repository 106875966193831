.ZoneDashBoard {
  .ant-pagination,
  .ant-table-pagination {
    float: right !important;
  }
  .ant-page-header-content {
    padding-top: 0px;
  }
}

.CustomerForm {
}
.divider-form {
  height: 580px;
}
.update-button {
  position: relative;
  bottom: 50px;
  left: 100px;
}
.step-divider {
  margin: 8px 0 !important;
}
.ZonerForm {
  height: 780px;
  .ant-card-head {
    margin-bottom: -10px;
  }
  .ant-card-head-title {
    padding: 5px 0;
  }
}

.highlighter-card {
  border-radius: 5px;
  box-shadow: 2 9px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.zone-card .ant-card-body {
  padding: 12px;
}
.zone-card-edit {
  height: 600px;
  .ant-form-item {
    margin-bottom: 10px;
  }
}
