.sla-monitoring {
  .sla-title {
    color: #3b5a98;
    position: relative;
    top: 10px;
    left: 15px;
  }
  .sla-customer_info {
    height: 84px;
    background: #f2f2f5;
    border: 1px dashed #dcdce4;
    &-label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(39, 49, 66, 0.8);
      mix-blend-mode: normal;
    }
    &-value {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #273142;

      mix-blend-mode: normal;
    }
  }
 
}
.single-list-back,
.single-list-back:hover,
.single-list-back:active {
  background: #1461d2;
  box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
  border-radius: 4px;
  color: #fff;
}
