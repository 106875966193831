.v-trip-list {
  overflow: hidden;

  .tag-input {
    width: 200px;
    margin-right: 5px;
    margin-bottom: 5px;
    float: left;

    input {
      background-color: #f8f8f8;
    }
  }
  .ant-list {
    background-color: #f8f8f8;
    .ant-list-item {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .ant-list-bordered {
    border-color: #f8f8f8;
  }
}

.v-trip-detail {
  .ant-list {
    background-color: #f8f8f8;
    .ant-list-item {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  .ant-list-bordered {
    border-color: #f8f8f8;
  }

  .btn-green {
    background-color: #389e0d !important;
    border-color: #389e0d !important;
  }
  .btn-green:disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
  .btn-gray {
    background-color: #bfbfbf !important;
    border-color: #bfbfbf !important;
  }
}

.v-trip-transaction-form .ant-row.ant-form-item {
  margin-bottom: 10px;
}
.verification-status-filters {
  .boxLittle {
    padding: 3px 25px;
    float: left;
    border: 1px solid #999;
    margin: 2px 2px;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;
    font-weight: 400;
    font-size: 14px;
  }

  .boxLittle:hover {
    padding: 3px 25px;
    float: left;
    border: 2px solid #999;
    margin: 2px 2px;
    // border-radius: 4px;
    // color: #fff;
    color: #0075f5;
    cursor: pointer;
    background: #eff6fd;
    // border:  0px;
    // background: rgba(59, 90, 152, 0.9);
    border-color: #0075f5;
    // outline-style: solid;
    font-weight: 400;
    font-size: 14px;
  }

  .pad10 {
    padding: 3px;
  }

  .boxLittleSelected {
    padding: 3px 25px;
    float: left;
    // border:  0px;
    border: 2px solid #999;
    margin: 2px 2px;
    // border-radius: 4px;
    // color: #fff;
    cursor: pointer;
    background: #eff6fd;
    // outline-style: solid;
    // background: rgba(59, 90, 152, 0.9);
    color: #0075f5;
    border-color: #0075f5;
    font-weight: 400;
    font-size: 14px;
  }
  .boxusertitles {
    margin: 7px 10px;
    font-size: 14px;
    font-weight: 500;
  }
}

.v-trip-view-button {
  background: #fbfcfc;
}