.app-popup {
    width: 445px;
    height: 400px;
    border-radius: 4px;
    .input-label {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9ea0a5;
    }
    .btn-danger {
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
        background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
        color: white;
        margin-right: 10px;
    }
    .btn {
        border-radius: 4px;
        background-color: #ffffff;
        color: #000000;
        margin-right: 10px;
    }
    .btn-success {
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
        background-image: linear-gradient(to top, #34aa44, #38b249);
        color: white;
        margin-right: 10px;
    }
    .btn:hover {
        color: #000000;
    }
    .btn-danger:hover {
        box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
        background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
        color: white;
    }
    .btn-success:hover {
        box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
        background-image: linear-gradient(to top, #34aa44, #38b249);
        color: white;
    }
}
