.ant-collapse {
    .ant-collapse-item {
        .ant-collapse-header {
            padding: 5px 0px 5px 10px !important;
            .anticon {
                left: initial !important;
                right: 16px !important;
            }
        }
    }
}

.error-notification {
    background-image: linear-gradient(to bottom, #e73c1e, #cf3014) !important;
    color: white !important;
    .ant-notification-notice-message {
        color: white !important;
    }
}

.success-notification {
    background-image: linear-gradient(to top, #34aa44, #38b249) !important;
    color: white !important;
    .ant-notification-notice-message {
        color: white !important;
    }
}