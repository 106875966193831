.inbound-scan {
  .scan-row {
    .ant-col {
      margin-bottom: 1em;
    }
  }

  .text-center {
    text-align: center;
  }

  .element-splitter {
    background-color: #eaeaea;
    border-radius: 50%;
    padding: 8px;
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
  }

  .curernt-scan {
    font-size: 16px;

    .anticon {
      svg {
        fill: #69B480 !important;
      }
    }
  }

  .details {
    background: #FFFFFF;
    border: 1px solid #DFE3EE;
    border-radius: 6px;
    margin: 0 20px 0 0px;
    width: 320px;
    display: inline-block;

    .destination {
      background: #3B5A98;
      border-radius: 6px 0 0 6px;
      font-size: 22px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      padding: 6px 12px;
      font-weight: bold;
    }
  }

  .timestamp {
    font-size: 12px;
    color: rgba(124, 124, 124, 0.87);
    letter-spacing: 0;
    font-weight: bold;
    float: right;
    padding: 8px 13px 8px 0;
    text-align: center;
  }

  .response-sync {
    p {
      margin: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
