.errorLog{
    color:red;
    text-align: center;
    padding: 12px 0px 0px 0px;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-weight: 700;
    font-size: 16px;
}

.blinking{
	animation:blinkingText 5s infinite;
}
@keyframes blinkingText{
	0%{		color: transparent;	}
	49%{	color: red;	}
	50%{	color: red;	}
	99%{	color: red;	}
	100%{	color: transparent;	}
}