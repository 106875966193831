.location-modal {
    .lsn-btn-primary,
    .lsn-btn-primary:active,
    .lsn-btn-primary:hover {
        background: linear-gradient(0deg, #1665d8 2.22%, #1f6fe5 98.44%);
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
        border-radius: 4px;
        color: #ffffff;
    }
}
