.Filter {
  .filter-btn {
    width: 100px;
    height: 38px;
    justify-content: space-between;
    .filter-txt {
      font-weight: 500;
    }
  }
}

.filter-label {
  color: #9ea0a5;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.filter-popup-btn {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-image: linear-gradient(to top, #1665d8, #1f6fe5);
  color: white;

  &:hover,
  &:focus {
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    color: white;
  }
}

.margin-bottom-10 {
  margin-bottom: 10px;
}