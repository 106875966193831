.form-label-text {   
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #9ea0a5;
}

// th, .ant-descriptions-small .ant-descriptions-row > td.ant-descriptions-item.trip-descriptions-item {
//     padding-bottom: 1px !important;
// }
.trip-description-small {
    color: #757575;
}

.trip-load-button {
    background-color: #34aa44 !important;
    color: #ffffff !important;
}

.floting-left {
    width: 90%;
}

.floating-right {
    // position: relative;
    // left: 8rem;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f2f2f5;
}
.floating-right>a.ant-dropdown-link.ant-dropdown-trigger {
    position: absolute;
    left: 8px;
    top: 5px;
}
.edit-trip-title-route {
    line-height: 1.33;
    letter-spacing: normal;
    color: #38b148;
}

.edit-trip-title-details {
    line-height: 1.33;
    letter-spacing: normal;
    color: #1665d9;
}
.asset-form-item {
    margin-bottom: 10px !important;
}
.vehicle-gps {
    float: right;
    background: #f9bc62;
    position: absolute;
    right: 10px;
}
.ant-row.ant-form-item.form-item-row {
    margin-bottom: 0px !important;
}
.gps-icon {
    cursor: pointer;
}

.field-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.trip-truck-photo {
    color: red;
    font-weight: lighter;
    font-size: small;
}

.trip-listing-consent {
    font-size: 8px;
    

    &-approved {
        color: green;
    }

    &-pending {
        color: red;
    }
}

.undel-reasons {
    max-width: 500px !important;
}