.Tracking {
    display: flex;
    flex-direction: column;
    .Card {
        width: 100%;
    }

    .gutter {
        margin: 10px;
    }

    .smallMessage {
        width: 95%;
        margin: 0;
    }

    .tracking-primary-btn {
        border-radius: 4px;
        background-color: rgba(59, 90, 152, 0.9);
        color: white;

        &:hover,
        &:active,
        &:focus {
            background-color: rgba(59, 90, 152, 0.9) !important;
            color: white !important;
        }

        .track-icon {
            padding: 0 5px;
            width: 30px;
            height: 25px;
        }
    }

    .waybills-input {
        width: 75%;
        margin-right: 10px;
        max-height: 100px !important;
        overflow-y: auto;
    }
}