.LMSDashBoard {
    .ant-pagination, .ant-table-pagination {
        float: right !important;
      }
}
.form-label-text {   
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #9ea0a5;
}