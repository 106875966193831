.logo {
  text-align: center;
}

.container {
  position: relative;
}

.login-card {
  .ant-card-body {
    padding: 40px;
  }
}

.login-1 {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 200%;
}
.login-1 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}

.login-2 {
  position: fixed;
  top: -50%;
  left: 50%;
  width: 50%;
  height: 200%;
}
.login-2 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}

.form-label-text {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9ea0a5;
}

.login-button {
  width: 100%;
  border-radius: 4px;
  height: 42px;
  box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
  background-color: #3d5cf3 !important;
}

.forget-pass {
  text-align: right;
}

.centered {
  display: flex;
  width: 50vw;
  height: 100vh;

  justify-content: center;
  align-items: center;
}

.centered-1 {
  width: 50vw;
  height: 100vh;
}

.containers {
  display: inline-block;
}
.login-form {
  .ant-form-item {
    margin: 5px;
  }
}

.login-title {
  width: 136px;
  height: 32px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
}

.login-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 12px 16px 12px 0px !important;
  //   width: 62px;
  //   height: 22px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
}

.mobile-otp {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

.mobile-otp:focus {
  outline: 0;
  border-color: #40a9ff !important;
}

.mobile-otp:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.copywrite-text {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 50vw;
  /* width: 229px; */
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3e3f42;
}

// .ant-tabs-tab-active.ant-tabs-tab {
//   border-bottom: 5px solid #1665d8 !important;
//   width: 30%;
// }
