.rateBOs {
    & fieldset > div.row:last-child {
        display: flex;
        justify-content: flex-end;
    }
    .array-item, .array-item > div, .array-item > div > .form-group.field > fieldset{ 
        display: flex;
    }
    .array-item > div > .form-group.field > fieldset {
        flex: 1;
        gap: 75px;
    }
}
.box-charges-list {
    .ant-table table { 
        border-collapse: collapse;
        border: 1px solid lightgrey;
    }
    .ant-table-thead > tr, .ant-table-tbody > tr { 
        margin: 5px 0px;
        td, th {
            border: none;
        }
    }

    .box-charges-list-header, .box-charges-list-row:nth-child(even) { 
        background-color: #f9f9fa;
    }

    .box-charges-list-row {
        border: 1px solid #dfe2e5;
    }

    .box-charges-list-header, .box-charges-list-row {
        margin: 10px 0px;
        border-radius: 10px;
        > div {
            padding: 15px;
            align-items: center;
            display: flex;
        }
    }
    .lsn-btn-primary,
    .lsn-btn-primary:active,
    .lsn-btn-primary:hover {
        background: linear-gradient(0deg, #1665d8 2.22%, #1f6fe5 98.44%);
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
        border-radius: 4px;
        color: #ffffff;
    }
}

.charges-rate-tooltip.slab-charges .ant-tooltip-inner {
    background-color: #000;
    min-width: 225px;
}

#root_itemRatesBO {
    .ant-radio-group.ant-radio-group-outline, .input-wrapper {
        flex: 3;
    }
}

#ptlCharges {
    .form-group.field {
        .input-wrapper, .ant-radio-group, .ant-select {
            flex: 2 1;
        }
    }
}