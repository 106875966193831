.print-table .ant-table-body table {
    border: none !important;
}

.print-table .ant-table-bordered .ant-table-thead > tr:last-of-type > th:first-of-type, .ant-table-bordered .ant-table-tbody > tr > td:first-of-type {
    border-left: 1px solid #e8e8e8;
}

.print-table .ant-table-thead, .print-table .ant-table-thead tr:first-of-type > th {
    background: none;
    border-right: none;
    text-align: left;
}
table.thc-details, table.thc-details td{
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}
table.thc-details td:nth-child(1) {
    width: 125px;
}
table.thc-details td:nth-child(2) {
    width: 150px;
}
table.thc-details th {
    padding: 10px;
    text-align: center;
}

.print-table {
    border-color: #000 !important;
    background: none !important;
    th, tr, td, .ant-table-placeholder {
        border-color: #000 !important;
        background: none !important;
        font-size: 12px !important;
    }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 5px;
    }
    th.short-column, td.short-column {
        max-width: 55px;
    }
    th.long-column, td.long-column {
        min-width: 100px;
    }
}
.waybill-barcode {
    width: 100%;
    & svg {
        height: 65px;
        width: 100%;
    }
}