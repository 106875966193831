.clearfix {
  overflow: auto;
}

.Consignments {
  background-color: #ffffff !important;

  .table {
      padding: 20px 0 !important;
  }
  .table td {
    width: 50% !important;
  }

  .borders {
    border: 1px solid #eaeaea !important;
  }

  .border-bottom {
    border-bottom: 1px solid #eaeaea;
  }

  .mini-box {
    border: 1px solid #eaeaea;
    padding: 10px !important;
    height: 50px;
    min-height: 50px;
    margin: 10px 0 13px;
    border-radius: 5px;
  }

  .col-3 td {
    width: 33.33% !important
  }

  .h3 {
    font-size: 1.5em;
  }

  textarea {
    border: 1px solid #eaeaea;
    width: 100%;
    resize: none
  }

  .table-header {
    background-color: #f3f3f3;
    padding: 10px;
    font-weight: 700;
  }

  .page-breaker {
    page-break-after: always
  }
}
