.form-group.field {
    .row.array-item-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .array-item {
            display: flex;
            align-items: center;
            gap: 15px;
            position: relative;
            & > *:first-child {
                flex: 1;
            }
        }
    }
}

i.glyphicon { display: none; }
.btn-add, .btn-add:hover, .array-item-remove {
    border-radius: 50%; 
    width: 20px;
    height: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    cursor: pointer;
    &::after { 
        display: flex;
        font-size: 12px;
    }
}
.btn-add, .btn-add:hover {
    background: #1461D2;
    border: none;
    &::after {
        color: #fff;
        &::after { 
            content: '+';
        }
    }
}
.array-item-remove {
    background: none;
    color: #e9201c;
    border: 1px solid #e9201c;
    &::after { 
        content: '-';
    }
}
.array-item-move-up::after { content: 'Move Up'; }
.array-item-move-down::after { content: 'Move Down'; }

i.glyphicon {
    display: none;
}
.btn-add::after {
    content: "+";
}


.form-group.array-columns > fieldset.field-array{ 
    flex-direction: column;
}
.form-group.array-columns > fieldset.field-array .row.array-item-list {
    .array-item:not(:first-of-type) fieldset > div > .control-label { 
        display: none;
    }
    .array-item:first-of-type .array-item-toolbox { 
        margin-top: 38px;
    }
}
.form-group.array-columns {
    & fieldset > div.row:last-child {
        display: flex;
        justify-content: flex-end;
    }
    & > fieldset { 
        display: flex !important;
        flex-direction: column !important;
    }
    .array-item, .array-item > div, .array-item > div > .form-group.field > fieldset{ 
        display: flex;
    }
    .array-item > div > .form-group.field > fieldset {
        flex: 1;
        gap: 75px;
    }
}
.lsn-form.read-only .form-group.array-columns {
    & fieldset > div.row:last-child, .btn-add, .array-item-remove {
        display: none;
    }
}