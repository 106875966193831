.b2b-lite-booking {
  .ant-select-selection__choice__remove {
    visibility: hidden;
  }

  .pull-right {
    float: right;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .input-margin-lr {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mr-3 {
    margin-right: 10px;
  }
  .ml-3 {
    margin-left: 10px;
  }

  .font-size-5 {
    font-size: 5px;
  }

  .ant-input {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #3e3f42;
  }

  .ant-input-number-input-wrap {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #3e3f42;
  }

  .ant-select-selection__placeholder {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000;
  }

  .ant-select-selection-selected-value {
    color: #000;
  }

  .ant-btn-link {
    color: #3b5a98;
  }

  .head-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    margin-bottom: 50px !important;
  }

  .ant-input-suffix {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #9ea0a5;
  }

  .ant-form-item-label label {
    width: 81px;
    height: 18px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #9ea0a5;
  }

  .ant-input-group-addon {
    button {
      border-radius: 4px;
      border: solid 1px #e2e5ed !important;
      background-color: #ffffff !important;

      span {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #3b5a98;
      }
    }
  }

  .ant-calendar-footer-btn {
    a:hover {
      color: #3b5a98 !important;
    }
  }

  .ant-select {
    width: 100% !important;
  }

  .pickup-select {
    .ant-select {
      width: 200px !important;
    }
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100% !important;
  }
  .booking-fileupload {
    position: relative;
    top: 40px;
    right: 35px;
    z-index: 99;
    .ant-upload-list {
      display: none !important;
    }
  }

  .invoice-fileupload {
    .ant-upload-list {
      display: none !important;
    }
  }
  .invoice-fileupload-index-0 {
    top: 28px !important;
    position: relative;
    .ant-upload-list {
      display: none !important;
    }
  }

  .booking-imageviewer {
    position: relative;
    right: 400px;
    /* width: 37px; */
    height: 1px !important;
    z-index: 999;
  }

  .invoice-delete-button {
    cursor: pointer;
    position: relative;
    top: 18px;
    font-size: 20px;
    color: #999;
    transition: all 0.3s;
  }
  .invoice-delete-button:hover {
    color: #777;
  }
  .invoice-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .invoice-delete-button-index-0 {
    cursor: pointer;
    position: relative;
    top: 45px;
    font-size: 20px;
    color: #999;
    transition: all 0.3s;
  }
  .invoice-delete-button-index-0:hover {
    color: #777;
  }
  .invoice-delete-button-index-0[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }


  .invoice-details-text {
    width: 79px;
    height: 18px;
    flex-grow: 0;
    margin: 0 27px 16px 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #9ea0a5;
  }
  .add-invoice {
    font-family: Roboto;
    font-size: 14px;
    // font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
    background-color: #091c9b;
  }

  .invoice-images-index-0 {
    margin-top: 30px;
  }
}
