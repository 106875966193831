.TrackingFooter {
    display: flex;
    justify-content: space-between;
    height: 50px;
    .footer-left {
        display: flex;
        width: 30%;
        .contact-container {
            display: flex;
            flex-direction: column;
            margin: 0px 12px;
            .contact-number {
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.56;
                letter-spacing: normal;
                color: #3e3f42;
                margin-bottom: 5px;
            }
            .contact-name {
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9ea0a5;
                margin-bottom: 5px;
            }
        }
        .phone-icon {
            font-size: 27px;
            transform: rotateY(180deg);
            margin-top: 17px;
        }
    }
    .footer-right {
        padding-top: 12px;
        .btn-danger {
            border-radius: 4px;
            box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
            background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
            color: white;
            margin-right: 10px;
        }
        .btn-success {
            border-radius: 4px;
            box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
            background-image: linear-gradient(to top, #34aa44, #38b249);
            color: white;
            margin-right: 10px;
        }
    }
    .del-undel-form {
        position: absolute;
        right: 0;
        bottom: 0px;
    }
}