.vehicleTitlebox {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #000000;
    padding: 0px 0px 20px 0px;
  }

  .bluecircle2 {
    width: 20px;
    height: 20px;
    font-family: Roboto;
    font-size: 10.3px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    border-radius: 50%;
    line-height: 2;
    margin: 12px 120px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    background-color: #091c9b;
}

.pb_20{
  padding-bottom: 20px;
}

  .vehicle-card{
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
    background-color: #ffffff;
  }

  .divider {
    height: 1px;
    background-color: #eaedf3;
    clear:both;
  }

  .vehicle_list {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
    padding: 12px 24px;
    float: left;
  }

  .vehicle_box{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
    padding: 12px 24px 0px 24px;
    position: relative;
    cursor: pointer;
    border-left: 3px solid #fff;
  }

  .vehicle_box:hover{
    background-color: #f6f9fd;
    border-left: 3px solid #4388ed;
  }

  .vehicleNumber{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
    float: left;
  }

  .vehicleAddr {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    padding: 0px 24px 10px 24px;
  }

  .vehicleDate {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #9ea0a5;
    padding: 2px 0px;
  }

  .statusRunning {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #1665d8;
    padding: 2px 0px;
    float: right;
  }

  .statusStopped {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #e6492d;
    padding: 2px 0px;
    float: right;
  }

  .vehicleTag {
    border-radius: 4px;
    background-color: #ebf3ff;
    font-size: 10px;
    padding: 2px 5px;
    float: right;
  }

  .pt_10{
    padding-top: 10px;
  }

  .vehiclescroll{
    height: 500px;
    overflow: scroll;
  }


  .noData {
    text-align: center;
    font-size: 20px;
    padding: 50px 0px;
}

