.input-loading { 
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: zp-fd-placeholder;
    animation-name: zp-fd-placeholder;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f2f2f2;
    background: -webkit-gradient(linear,left top,right top,color-stop(8%,#f2f2f2),color-stop(18%,#ddd),color-stop(33%,#f2f2f2));
    background: -webkit-linear-gradient(left,#f2f2f2 8%,#ddd 18%,#f2f2f2 33%);
    background: linear-gradient(to right,#f2f2f2 8%,#ddd 18%,#f2f2f2 33%);
}
.lsn-form.loading-form {
    input, textarea,
    .ant-select .ant-select-selection {
        @extend .input-loading;
    }
}
input.loading, textarea.loading, .ant-select.loading .ant-select-selection {
    @extend .input-loading;
}

@-webkit-keyframes zp-fd-placeholder {
    0% { background-position: -468px 0 }

    100% { background-position: 468px 0 }
}

@keyframes zp-fd-placeholder {
    0% { background-position: -468px 0 }

    100% { background-position: 468px 0 }
}