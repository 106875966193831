.zone-mapping {
  li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher), li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper, li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {    
    color: black !important;
    cursor: not-allowed !important;;
  }
  .ant-tree-node-content-wrapper {
    width: 30vw;
  }
  .ant-select {
    width: 100% !important;
  }
  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent;
  }
  .state-tree {
    margin: 0 0 6px;
    // padding: 8px 8px 8px 12px;
    border-radius: 6px;
    border: solid 1px #eff0f0;
    background-color: #fff;
  }
  .city-tree {
    // padding: 4px 7px 4px 43px;
    box-shadow: inset 0 -1px 0 0 rgba(37, 37, 37, 0.12);
    background-color: #f9f9fb;
  }
  .pincode-tree {
    box-shadow: inset 0 -1px 0 0 rgba(37, 37, 37, 0.12);
    background-color: #fff;
    .city-name {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #1968dc;
    }
  }
  .add-action {
    border-radius: 3.2px;
    box-shadow: 0 0.8px 0.8px 0 rgba(19, 31, 21, 0.1);
    background-color: #f2f2f5;
    padding: 3.4px 3.4px 3.4px;
  }

  
}

