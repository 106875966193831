.message-logo {
  text-align: center;
  margin: 5% 0 !important;
}

.ghost-button {
  margin-top: 20px !important;
}

.centered {
  display: flex;
  width: 50vw;
  height: 100vh;

  justify-content: center;
  align-items: center;
}

.centered-1 {
  width: 50vw;
  height: 100vh;
}

.containers {
  display: inline-block;
}
.login-form {
  .ant-form-item {
    margin: 5px;
  }
}

.reset-password-title {
  width: 159px;
  height: 32px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #000000;
}

.login-title {
  width: 172px;
  height: 32px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #000000;
}

.login-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 12px 16px 12px 0px !important;
  //   width: 62px;
  //   height: 22px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
}

.copywrite-text {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 50vw;
  /* width: 229px; */
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3e3f42;
}