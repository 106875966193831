.CustomerDashBoard {
    .ant-pagination, .ant-table-pagination {
        float: right !important;
      }
}

button.user-status.ant-switch {
  background-color: #e74c3c;
}
button.user-status.ant-switch.ant-switch-checked {
  background-color: #2ecc71;
}