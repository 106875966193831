.CustomerDashBoard {
    .ant-pagination, .ant-table-pagination {
        float: right !important;
      }
}

.modal-footer {
  justify-content: normal !important;
  border: 1px solid #999;
}

.close {
  cursor: pointer;
}



  .start-loc {
    content: ' ';
    display: inline-block;
    border-radius: 50%;
    top: 2px;
    width: 10px;
    height: 10px;
    background: red;
    border: 3px solid red;
    margin-right: 5px;
    margin-left: 5px;
  }

  .inter-loc {
    content: ' ';
    display: inline-block;
    border-radius: 50%;
    top: 2px;
    width: 10px;
    height: 10px;
    background: #22c0e8;
    // border: 1px solid #22c0e8;
    margin-right: 5px;
    margin-left: 5px;
  }

  .end-loc {
    content: ' ';
    display: inline-block;
    border-radius: 50%;
    top: 2px;
    width: 10px;
    height: 10px;
    background: green;
    border: 3px solid green;
    margin-right: 5px;
    margin-left: 5px;
  }
// }

.modal-body {
  overflow: scroll;
  max-height: 500px;
}

.swal2-progresssteps li {
  display: list-item;
  position: relative;
}

.swal2-progresssteps .swal2-progresscircle {
  background: #22c0e8;
  border-radius: 2em;
  color: #fff;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
  z-index: 20;
}

.swal2-progresssteps {
  span {
    top: 0px;
    left: 40px;
    color: #000;
    position: absolute;
    width: 500px;
    text-align: left;
    font-size: 14px;
  }
}

.start {
  background: red !important;
}

.end {
  background: green !important;
}

.swal2-progresssteps .swal2-progressline {
  background: grey;
  z-index: 10;
  margin-left: 12px;
  height: 50px;
  width: 0.3em;
}

.swal2-progresssteps .swal2-progressline {
  label {
    font-size: 12px;
    background: #fff;
    margin-top: 15px;
    margin-left: -35px !important;
  }
}

.heigth-50 {
  height: 50px;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #8A9CC2;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep~.swal2-progresscircle,
.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep~.swal2-progressline {
  background: #D8D8D8;
}
