pre {
    float:left;
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    float: left;
    width: 25rem;
    margin: 0 10px 0 0;
    position: sticky;
    top: 0;
}

.title {
	text-align: center;
}


.download-btn {
    float: right;
    margin-top: 10px;
    margin-right: 20rem;
}

.button-success {
	box-shadow:inset 0px 34px 0px -15px #3dc21b;
	background-color:#44c767;
	border-radius:5px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	font-weight:bold;
	padding:9px 23px;
	text-decoration:none;
	text-shadow:0px -1px 0px #2f6627;
}
.button-success:hover {
	background-color:#5cbf2a;
}
.button-success:active {
	position:relative;
	top:1px;
}
.ui-text,
.ui-text:active,
.ui-text:focus {
	text-decoration: underline;
	color: #1890ff
}

.textarea-ui {
	margin-top: 10px;
	margin-left: 10px;
	width: 700px;
	height: 100px;
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
	border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
	border-image: none;
	border-radius: 6px 6px 6px 6px;
	border-style: none solid solid none;
	border-width: medium 1px 1px medium;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
	color: #555555;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 1em;
	line-height: 1.4em;
	padding: 5px 8px;
	transition: background-color 0.2s ease 0s;
  }
  
  
  .textarea-ui:focus {
	  background: none repeat scroll 0 0 #FFFFFF;
	  outline-width: 0;
  }