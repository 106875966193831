.indent {
    &_listing {
        &-title {
            font-family: Roboto;
            font-size: 26px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.46;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }        
    }

    .visited-link {
        color: #1665d8;
        &:hover,
        &:active {
            color: #1665d8;
        }
    }
}
tr.ant-table-row.indent-listing_table-color {
    background-color: #cceaf8 !important;
}
