.Audit-Notice {
  z-index: 1;
  background-color: #D67575;
  padding: 10px 15px;
  color: #fff;
  padding-left: 35px;
  min-height: 56px;

  p {
    padding-top: 6px;
    margin: 0;
    display: inline-block;
    font-size: 16px;
  }

  .logo-img {
    width: 14em;
    height: 50%;
    vertical-align: middle;
    margin-left: 6px;
  }
}

.recheck {
  float: right
}

.ant-modal-wrap.audit-modal {
  top: -62px;
}

.audit-modal {
  .ant-modal-body {
    padding: 0;

    .header {
      background-color: #3FC380;
      padding: 15px;
      border-bottom: 1px solid #e9ecef;
      color: #fff;
      font-size: 150%;
      border-radius: 3px;
    }

    p {
      font-size: 14px;
      color: #000;
      padding: 20px 14px;
      margin-bottom: 0;
    }

    .footer {
      text-align: right;
      padding: 0 20px 20px 0;
    }
  }

  .btn {
    background-color: #3B5A98;
    border-color: #3B5A98;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    border-radius: 2px;
    font-size: 16px;
    padding: 0px 20px;
  }
}

.clickHere {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.audit-status {
  background-color: #3B5A98;
  border-color: #3B5A98;
  color: #fff;
  border-radius: 2px;
  padding: 0px 10px;
}
