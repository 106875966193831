

.confirm-text {
    font-size: 18px;    
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;

    &-message {
        font-size: 18px;
        display: inline-block;
    }

    &-icon {
        float: right;
        font-size: 50px;
        margin-left: 25px;
        color: #59ABE3;
    }
}

.li-report {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    &-label {
        font-size: 14px;
        color: #A5B4CF;
        margin: 14px 0 0;
    }
}

.ant-checkbox-inner {
    border: 2px solid #4680ff;

    &::after {
        left: 20%;
    }
}

.right-content {
    padding-left: 10px;
}
