.contract-management {
    .lsn-btn-primary,
    .lsn-btn-primary:active,
    .lsn-btn-primary:hover {
        background: linear-gradient(0deg, #1665d8 2.22%, #1f6fe5 98.44%);
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
        border-radius: 4px;
        color: #ffffff;
    }
    .lsn-btn-primary:disabled {
        box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
        background-color: #1b6be0;
        color: #ffffff;
        opacity: 0.54;
    }

    .lsn-btn-secondary,
    .lsn-btn-secondary:active,
    .lsn-btn-secondary:visited,
    .lsn-btn-secondary:hover {
        // background: linear-gradient(0deg, #0000 2.22%, #1f6fe5 98.44%);
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1);
        border-radius: 4px;
        color: #000000;
    }
    .lsn-btn-success,
    .lsn-btn-success:active,
    .lsn-btn-success:hover {
        background: #61ce79;
        border-radius: 4px;
        color: #ffffff;
    }
    .lsn-btn-success:disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
    }
    .lsn-btn-error,
    .lsn-btn-error:active,
    .lsn-btn-error:hover {
        background: #f44336;
        border-radius: 4px;
        color: #ffffff;
    }

    .visited-link {
        color: #1665d8;
        &:hover,
        &:active {
            color: #1665d8;
        }
    }
    .total-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding: 10px 8px;

        // position: absolute;
        width: 110px;

        background: #ffffff;
        border: 1px solid #dfe2e5;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .ant-divider-horizontal {
        margin: 18px 0px;
    }
    .ant-alert.ant-alert-error {
        margin-bottom: 15px;
    }
}

.lane-charges,
.upload-contract-proof {
    color: #1665d8;
    cursor: pointer;
    padding: 5px 15px;
    border: 1px solid #1665d8;
    text-align: center;
    border-radius: 5px;
}
.lane-charges.disabled-option {
    cursor: not-allowed;
}

.contract-filters {
    .boxType {
        background: #fff;

        border: 1px solid;
        border-radius: 4px;
        padding: 8px 16px;
        line-height: 16px;
        height: 32px;
        // font-weight: 500;
        // color: #1867db;
        cursor: pointer;
        &:hover {
            background: #f0f4f9;
            border: 1px solid #1868dc;
            color: #1867db;
        }
    }
    .boxTypeSelected {
        background: #f0f4f9;
        border: 1px solid #1868dc;

        border-radius: 4px;
        padding: 8px 16px;
        line-height: 16px;
        height: 32px;
        font-weight: 500;
        color: #1867db;
        cursor: pointer;
    }
}

#root_rov {
    margin-top: -10px;
    & div.form-group.field.field-number:first-of-type {
        position: relative;
        top: 28px;
    }
}

.file-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    position: relative;
    font-size: 20px;
    .file-remove {
        cursor: pointer;
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: red;
        bottom: -6px;
        right: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        .delete-icon {
            color: #fff;
            font-size: 12px;
        }
    }
}
.contract-steps {
    width: 554px !important;
}

.contract-radio {
    bottom: 5px;
    right: 2px;
}

form#AccessorialCharges {
    margin-bottom: 30px;
}
 
form#FuelCharges > .form-group.field.field-object > fieldset > .form-group.field:nth-child(11) {
    position: relative;
    left: -35%;
}

label[for='root_sourceLocationType'] + .input-wrapper {
    height: 32px !important;
}
#AccessorialCharges, #OtherCharges {
    .form-group.field {
        .input-wrapper, .ant-radio-group, .ant-select {
            flex: 1 1;
        }
    }
}